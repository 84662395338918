import dayjs, { Dayjs, ManipulateType } from 'dayjs';
import { cond, mapValues } from 'lodash/fp';

import { converWordToInteger } from '@/ext/lib/number';
import { DATE_FORMAT } from '../google/constants';

export type DateRange = {
  from?: string;
  to?: string;
};

export const getDateRange = (rawDate?: string): DateRange => {
  const formats = [
    'YYYY-MM-DD',
    'YYYY-MM',
    'YYYY',
    'MM/DD/YYYY',
    'MM/DD/YY',
    'M/D/YYYY',
    'M/D/YY',
    'MM/YYYY',
    'M/YYYY',
    'MM/YY',
    'M/YY',
  ];
  const ymdReg = /^(?<year>\d{4})(-(?<month>\d{2}))?(-(?<day>\d{2}))?$/;
  const prevReg = /^(last|past|prev(ious)?) (?<num>\d+)? ?(?<unit>\w+)/;
  const date = rawDate
    ?.split(' ')
    ?.map((d) => converWordToInteger(d) || d)
    ?.join(' ');

  const tokens = rawDate?.split(' ');

  if (tokens?.length === 4) {
    const from = dayjs(tokens[1], formats, true);
    const to = dayjs(tokens[3], formats, true);

    return mapValues((x) => x?.format(DATE_FORMAT), { from, to });
  } else if (tokens?.[0] === 'from' && rawDate?.includes('-')) {
    const [fromString, toString] = rawDate
      ?.replace('from ', '')
      .trimStart()
      .split('-')
      .map((date) => date.trim());

    const from = dayjs(fromString, formats, true);
    const to = dayjs(toString, formats, true);

    return mapValues((x) => x?.format(DATE_FORMAT), { from, to });
  } else if (tokens?.[0] === 'before') {
    const from = dayjs('1970-01-01', formats, true);
    const to = dayjs(tokens[1], formats, true);

    return mapValues((x) => x?.format(DATE_FORMAT), { from, to });
  } else if (tokens?.[0] === 'after') {
    const from = dayjs(tokens[1], formats, true).add(1, 'year');
    const to = dayjs();

    return mapValues((x) => x?.format(DATE_FORMAT), { from, to });
  } else if (
    tokens?.length === 3 &&
    (tokens[1] === 'to' || tokens[1] === '-') &&
    converWordToInteger(tokens[0]) &&
    converWordToInteger(tokens[2])
  ) {
    const from = dayjs(tokens[0], formats, true);
    const to = dayjs(tokens[2], formats, true);

    return mapValues((x) => x?.format(DATE_FORMAT), { from, to });
  }

  const ymd = date?.match(ymdReg)?.groups;
  const unit = (['day', 'month', 'year'] as const).find((x) => ymd?.[x]);
  const prev = date?.match(prevReg)?.groups;
  const from = cond<typeof prev | undefined, Dayjs | undefined>([
    [
      (p) => !!p,
      (p) =>
        dayjs().subtract(
          parseInt(p?.num || '1', 10),
          p?.unit as ManipulateType,
        ),
    ],
    [() => !!unit, () => dayjs(date, formats).startOf(unit!)],
  ])(prev);
  const to = cond<typeof prev | undefined, Dayjs | undefined>([
    [() => !!unit, () => dayjs(date, formats).endOf(unit!)],
  ])(prev);

  return mapValues((x) => x?.format(DATE_FORMAT), { from, to });
};
