import { IconButton, IconButtonProps, styled } from '@mui/material';
import classNames from 'classnames';
import Pin from '../../atoms/Icon/Pin';
import Unpin from '../../atoms/Icon/Unpin';
import PinOutlined from '../../atoms/Icon/PinOutlined';

export interface PinButtonProps<T> extends IconButtonProps {
  onPin: (option: { option: T; value: boolean }) => void;
  isVisible?: boolean;
  isPinned: boolean;
  option: T;
  disablePadding?: boolean;
}

const Button = styled(IconButton)(({ theme }) => ({
  svg: {
    color: theme.palette.icon?.high,
  },
  '&.disablePadding': {
    padding: 0,
  },
  '&:hover': {
    backgroundColor: theme.palette.fill.medium,
  },
}));

const PinButton = <T extends unknown>({
  onPin,
  isPinned,
  option,
  disablePadding,
  className,
  isVisible = false,
  ...props
}: PinButtonProps<T>) => (
  <Button
    className={classNames(className, { disablePadding, isVisible })}
    onClick={(e) => {
      e.stopPropagation();
      onPin({
        option,
        value: !isPinned,
      });
    }}
    {...props}
  >
    {isPinned && !isVisible && <Pin className="pinIcon" />}
    {isPinned && isVisible && <Unpin className="hoverIcon" />}
    {!isPinned && isVisible && <PinOutlined className="hoverIcon" />}
  </Button>
);

export default PinButton;
