import { SubscriptionType } from '../../../types';
import { SKIM_FILTER_OPTIONS } from './filters/skim';
import { FilterOption, FilterOptionId } from './types/filterOption';

const groups: {
  [key: string]: {
    options?: FilterOption[];
    restrictedTiers?: SubscriptionType[];
  };
} = {
  skim: {
    options: SKIM_FILTER_OPTIONS,
    restrictedTiers: [SubscriptionType.lite],
  },
  xray: {
    restrictedTiers: [SubscriptionType.lite],
  },
};

export const isFilterDisabled = (
  filter: FilterOptionId,
  subscription = SubscriptionType.lite,
) =>
  !!groups[filter.groupName]?.restrictedTiers?.includes(
    subscription || SubscriptionType.lite,
  ) ||
  !!groups[filter.groupName]?.options
    ?.find((option) => option.value === filter.value)
    ?.restrictedTiers?.includes(subscription || SubscriptionType.lite);
