import { useSetDialog } from '@/ext/app/state/dialog';
import { useSetSelectedLens } from '@/ext/app/state/lenses';
import { useSetLensInitialValues } from '@/ext/app/state/lensInitialValues';
import { PERSONAL_SPACE } from '@/ext/app/state/notes/constant';
import { useTeamById } from '@/ext/app/state/teams';
import { ExploreFormLensFilterListItemProps } from './types';

interface Props extends ExploreFormLensFilterListItemProps {
  teamName: string;
  onEdit: () => void;
}

export const useExploreFormLensFilterListItemProps = (
  props: ExploreFormLensFilterListItemProps,
): Props => {
  const { lens } = props;
  const { data: team } = useTeamById(lens?.teams[0]?.teamId);
  const setDialog = useSetDialog();
  const setSelectedLens = useSetSelectedLens();
  const setInitialValues = useSetLensInitialValues();

  const handleEdit = () => {
    setSelectedLens(lens);
    setDialog({ dialog: 'lens' });
  };

  const handleDuplicate = () => {
    setSelectedLens(undefined);
    setInitialValues({
      description: lens.description,
      parentLensId: lens.lensId,
      resources: lens.resources.map(({ url }) => url),
      title: `${lens.title} -copy`,
    });
    setDialog({ dialog: 'lens' });
  };

  return {
    ...props,
    lens,
    // eslint-disable-next-line no-nested-ternary
    teamName: team ? team.name : lens?.public ? 'Public' : PERSONAL_SPACE.name,
    onEdit: lens.public ? handleDuplicate : handleEdit,
  };
};
