import { Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { FilterOptionId } from '@/ext/app/state/search/types/filterOption';
import ExploreFormFilterListItem from '../ExploreFormFilterListItem';

export interface ExploreFormXrayFilterListItemProps extends FilterOptionId {
  mentions: number;
  pages: number;
  onHighlightOption: (id: FilterOptionId) => void;
}

const ExploreFormXrayFilterListItem: FunctionComponent<ExploreFormXrayFilterListItemProps> =
  ({ mentions, pages, label, groupName, value, ...props }) => (
    <ExploreFormFilterListItem
      {...props}
      groupName={groupName}
      label={label}
      value={value}
      details={
        <Typography variant="body2" color="text.disabled">
          {`${mentions}x in ${pages || 0} source${pages || 0 > 1 ? 's' : ''}`}
        </Typography>
      }
    />
  );

export default ExploreFormXrayFilterListItem;
