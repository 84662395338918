import {
  atom,
  SetterOrUpdater,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';

import { TeamWithSharedLensAttribute } from './teams';

export interface FormFields {
  title: string;
  description?: string | null;
  parentLensId?: number;
  resources: string[];
}

export type ValueType = Partial<FormFields> | undefined;

export const lensInitialValuesState = atom<ValueType>({
  key: 'lensInitialValues',
  default: undefined,
});

export const useLensInitialValues = (): ValueType =>
  useRecoilValue(lensInitialValuesState);

export const useSetLensInitialValues = (): SetterOrUpdater<ValueType> =>
  useSetRecoilState(lensInitialValuesState);

export const lensInitialTeamState = atom<
  TeamWithSharedLensAttribute | undefined
>({
  key: 'lensInitialTeam',
  default: undefined,
});

export const useSetLensInitialTeam = (): SetterOrUpdater<
  TeamWithSharedLensAttribute | undefined
> => useSetRecoilState(lensInitialTeamState);

export const useLensInitialTeam = (): TeamWithSharedLensAttribute | undefined =>
  useRecoilValue(lensInitialTeamState);
