import flatMap from 'lodash/fp/flatMap';
import { DataPoint, MatchToken } from '@/ext/app/state/searchResultContent';

const REGEX = /\d+(,\d+)*(\.\d+)?/g;

const convertToken = ([token, type]: MatchToken) => {
  const match = token.match(REGEX)?.[0];

  const converted = Number(match);
  if (type !== 'DATE' && !Number.isNaN(converted)) {
    return converted;
  }
  return match;
};

/**
 * Checks if datapoint matches the datapoints in query
 */
export const isFactCheckMatch = (query: string, datapoint: DataPoint) => {
  const queryTokens = query.match(REGEX) || [];

  const datapointTokens = flatMap(convertToken, datapoint.matchTokens)
    .filter((t) => !!t)
    .map((token) =>
      typeof token === 'number' ? Math.round(token) : token,
    ) as (string | number)[];

  return queryTokens
    .map((token) => Math.round(Number(token)))
    .every(
      (q) => datapointTokens.includes(q) || datapointTokens.includes(String(q)),
    );
};

/**
 * Returns tokens that match the given query
 */
export const getFactCheckMatchTokens = (
  query: string,
  datapoint: DataPoint,
) => {
  const queryTokens = query.match(REGEX) || [];

  return datapoint.matchTokens.filter((token) => {
    let convertedToken = convertToken(token);

    if (typeof convertedToken === 'number') {
      convertedToken = Math.round(convertedToken);
    }

    return queryTokens.some((q) => Math.round(Number(q)) === convertedToken);
  });
};
