import { atom, selector, useRecoilValue, useSetRecoilState } from 'recoil';

export const exploreQueryRaw = atom<string>({
  key: 'exploreQuery',
  default: '',
});

export const useSetExploreQuery = () => useSetRecoilState(exploreQueryRaw);

export const exploreQueryParts = selector<{
  query: string;
  group: string | null;
}>({
  key: 'exploreQueryParts',
  get: ({ get }) => {
    const query = get(exploreQueryRaw);
    const [firstPart, secondPart] = query.split(':');
    if (secondPart !== undefined) {
      return {
        query: secondPart.trim(),
        group: firstPart.trim(),
      };
    }
    return {
      query,
      group: null,
    };
  },
});

export const useExploreQueryParts = (): {
  query: string;
  group: string | null;
} => useRecoilValue(exploreQueryParts);

export const useExploreQuery = (): string =>
  useRecoilValue(exploreQueryParts).query;

export const useExploreQueryGroup = (): string | null =>
  useRecoilValue(exploreQueryParts).group;
