import { useEffect } from 'react';

import type { Channel } from 'pusher-js';

interface Chunk {
  id: string;
  chunk: string;
  index: number;
  final: boolean;
}

export function usePusherEventWithChunking<T>(
  channel: Channel | undefined,
  event: string,
  callback: (data: T) => void,
): void {
  useEffect(() => {
    if (!channel) {
      return undefined;
    }

    channel.bind(event, callback); // Allow normal unchunked events.

    // Now the chunked variation. Allows arbitrarily long messages.
    const events: Record<string, { chunks: string[]; receivedFinal: boolean }> =
      {};
    const eventName = `chunked-${event}`;

    const handleMessage = (data: Chunk) => {
      if (!events[data.id]) {
        events[data.id] = { chunks: [], receivedFinal: false };
      }

      const ev = events[data.id];
      ev.chunks[data.index] = data.chunk;

      if (data.final) {
        ev.receivedFinal = true;
      }

      if (
        ev.receivedFinal &&
        ev.chunks.length === Object.keys(ev.chunks).length
      ) {
        callback(JSON.parse(ev.chunks.join('')));
        delete events[data.id];
      }
    };

    channel.bind(eventName, handleMessage);

    return () => {
      channel.unbind(event, callback);
      channel.unbind(eventName, handleMessage);
    };
  }, [callback, channel, event]);
}
