import { QueryStringValues } from '../google/utils';
import { SearchEngine } from './engine';
import { PERSONAL_SPACE } from './notes/constant';

/**
 * Search Query
 */
export interface SearchQuery extends Readonly<QueryStringValues> {
  searchQuery: string;
  engine?: SearchEngine;
}

/**
 * Search Query + Page
 */
export interface SearchQueryParams extends SearchQuery {
  page: number;
}

/**
 * Pages
 */
export interface Page {
  results: SearchResult[];
  ads: SearchResult[];
}

export enum SearchResultType {
  finding = 'finding',
  note = 'note',
  default = 'default',
}

export enum SearchResultSection {
  library = 'library',
  search = 'search',
  frequentlyCited = 'frequentlyCited',
}

export type SearchResultIcon = typeof PERSONAL_SPACE.value | 'team';

/**
 * Search results that are scraped.
 */
export interface SearchResult {
  // Published date shown with the description.
  date?: string;

  // The description / excerpt without any HTML.
  description?: string;

  // The description / excerpt with HTML.
  descriptionHTML?: string;

  // URL domain.
  domain: string;

  featured?: boolean;

  // Page title.
  title: string;

  // Page URL.
  url: string;

  // Icon for the resource.
  icon?: SearchResultIcon;

  // Type of search result: finding | note | default.
  type: SearchResultType;

  // Section of search result: library | search | frequentlyCited.
  noteId?: number;

  // Team ID of the note.
  teamId?: number;
}

export interface LinkedInRequestExtraction {
  url: string;
  force?: boolean;
}

/**
 * NLP Entity
 */
export interface Entity {
  value: string;
  mentions: number;
  pages?: number;
}
