import { reject, differenceWith, isEqual, pick, uniqWith } from 'lodash/fp';
import { useCallback } from 'react';

import {
  atom,
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { useTrackFilters } from '../../utils/useTrackFilters';
import { exploreQueryRaw } from './exploreQuery';
import { useFilterGroups, useKeyedFilterGroups } from './filterGroups';
import { SKIM_FILTER_OPTIONS, SKIM_GROUP_NAME } from './filters/skim';
import { XRAY_GROUP_NAME } from './filters/xray';
import { FilterOption, FilterOptionId } from './types/filterOption';
import { rawDatapointsState } from '../searchResultContent';

export const selectedFiltersState = atom<FilterOptionId[]>({
  key: 'selectedFilters',
  default: [],
});

const pickProps = (filter: FilterOptionId) =>
  pick(['value', 'groupName', 'label'], filter);

export const useSelectedFiltersState = () =>
  useRecoilState(selectedFiltersState);

export const useSelectedFilters = () => useRecoilValue(selectedFiltersState);

export const useSetSelectedFilters = () =>
  useSetRecoilState(selectedFiltersState);

export const useAddSelectedFilter = () => {
  const setSelectedFilters = useSetRecoilState(selectedFiltersState);
  const filterGroups = useKeyedFilterGroups();

  return useCallback(
    (filter: FilterOption) =>
      setSelectedFilters((filters) => {
        const { groupName } = filter;
        const remainingFilters = filterGroups[groupName]?.multi
          ? filters
          : reject(['groupName', groupName], filters);
        return uniqWith(isEqual, [...remainingFilters, pickProps(filter)]);
      }),
    [],
  );
};

export const useRemoveSelectedFilter = () => {
  const setSelectedFilters = useSetRecoilState(selectedFiltersState);

  return useCallback(
    (filter: FilterOption) =>
      setSelectedFilters((filters) =>
        differenceWith(isEqual, filters, [pickProps(filter)]),
      ),
    [],
  );
};

export const useSelectFilterOption = () => {
  const filterGroups = useFilterGroups();
  const trackFilters = useTrackFilters();

  return useRecoilCallback(
    ({ snapshot, set }) =>
      async (option: FilterOptionId) => {
        const newOptions = [option];
        const group = filterGroups.find(({ id }) => id === option.groupName);

        const selected = await snapshot.getPromise(selectedFiltersState);
        set(rawDatapointsState, []);

        if (
          option.groupName === XRAY_GROUP_NAME &&
          !selected.some(({ groupName }) => groupName === SKIM_GROUP_NAME)
        ) {
          newOptions.push(SKIM_FILTER_OPTIONS[0]);
        }

        /**
         * If the group does not have multi-select enabled, then we want to change
         * the selected option for that group to the new option.
         */
        if (!group?.multi) {
          const isGroupSelected = selected.some(
            ({ groupName }) => groupName === option.groupName,
          );
          if (isGroupSelected) {
            trackFilters(newOptions);
            set(selectedFiltersState, (state) => [
              ...state.filter(
                ({ groupName }) => groupName !== option.groupName,
              ),
              ...newOptions,
            ]);
            set(exploreQueryRaw, '');
            return;
          }
        }

        trackFilters(newOptions);

        set(selectedFiltersState, (state) => [...state, ...newOptions]);
        set(exploreQueryRaw, '');
      },
    [filterGroups],
  );
};
