import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import {
  atom,
  selector,
  useRecoilCallback,
  useRecoilState,
  useSetRecoilState,
} from 'recoil';
import superagent from 'superagent';
import { v5 as uuid } from 'uuid';
import {
  noteState,
  selectedNoteIdState,
  useCreateNote,
  useSelectedNoteId,
} from '@/ext/app/state/notes';
import { useEditor } from '../editor/context';
import { usePusherEventWithChunking } from '@/ext/app/utils/usePusherEventWithChunking';
import { GptRawDataResponse } from '@/ext/app/state/searchResultContent';
import { useChannelForId } from '@/ext/app/state/channel';
import { useSelectedIdeateProject, useUpdateIdeateProject } from './datapoints';
import { notepadPanelOpenState } from '@/ext/app/state/notepadPanel';
import { SearchResult } from '@/ext/app/state/types';

export const briefSummaryRawState = atom<Record<number, string>>({
  key: 'briefSummaryRawState',
  default: {},
});

const getTitle = (title?: string) => title || 'Untitled';

const SOURCE_REGEX = /\[((?:\d+,?)+)\]|(Source \d+)/gm;

export const briefSummaryState = atom({
  key: 'briefSummaryState',
  default: selector<string[]>({
    key: 'defaultBriefSummaryState',
    get: ({ get }) => {
      const lines = Object.values(get(briefSummaryRawState))
        .join('')
        .split('\n')
        .filter((l) => !!l);

      return lines.map((line) => line.replace(/\d+\. /, ''));
    },
  }),
});

export const briefSummaryReadyState = atom({
  key: 'briefSummaryReadyState',
  default: false,
});

export const useBriefSummaryPusherEvent = () => {
  const setBriefSummaryRawState = useSetRecoilState(briefSummaryRawState);
  const [briefSummaryReady, setBriefSummaryReady] = useRecoilState(
    briefSummaryReadyState,
  );
  const project = useSelectedIdeateProject();
  const channel = useChannelForId(uuid(project?.title || 'Untitled', uuid.URL));

  usePusherEventWithChunking<GptRawDataResponse>(
    channel,
    'brief-summary',
    ({ content, finished, index }) => {
      setBriefSummaryRawState((prev) => ({ ...prev, [index]: content }));
      if (briefSummaryReady !== finished) {
        setBriefSummaryReady(finished);
      }
    },
  );
};

export const useGenerateBrief = () => {
  const createNote = useCreateNote();
  const { editor } = useEditor();
  const project = useSelectedIdeateProject();
  const selectedNoteId = useSelectedNoteId();
  const { mutateAsync: updateProject } = useUpdateIdeateProject(true);
  const [shouldUpdate, setShouldUpdate] = useState(false);

  const { mutateAsync: summarize } = useMutation((content: string) =>
    superagent.post(`${process.env.NEXT_PUBLIC_NLP_BASE}/brief_summary`).send({
      sqid: uuid(getTitle(project?.title), uuid.URL),
      content,
    }),
  );

  const sections = project?.data?.sections as {
    title: string;
    queries?: {
      summary?: { content: string };
      summaries?: { message: string }[];
      searchResults: SearchResult[];
      id: string;
    }[];
  }[];

  const fetchBriefSummary = useRecoilCallback(
    ({ set, snapshot }) =>
      async () => {
        if (project) {
          let { noteId } = project.data || {};
          const note = await snapshot.getPromise(noteState(noteId));
          if (!note) {
            ({ noteId } = await createNote({
              title: getTitle(project.title),
            }));
          }
          set(notepadPanelOpenState, true);
          set(selectedNoteIdState, noteId);
          updateProject({
            projectId: project.projectId,
            ideateProject: {
              data: {
                ...project.data,
                noteId,
              },
            },
          });
          setShouldUpdate(true);
        }
      },
    [project, editor, summarize, createNote],
  );

  useEffect(() => {
    if (editor && !editor?.isDestroyed && editor.isEmpty && shouldUpdate) {
      let currentIndex = 1;
      const sourceIndexes: Record<string, number> = {};

      const content = sections
        .filter(({ queries }) =>
          queries?.some(
            (query) => query.summary?.content || query.summaries?.length,
          ),
        )
        .map(({ title, queries }) => {
          const allSummaries = queries
            ?.filter(
              ({ summary, summaries }) =>
                !!summary?.content || !!summaries?.length,
            )
            .map((query) => {
              const chunks = [
                ...(query.summary?.content.split(SOURCE_REGEX) || []),
                ...(query.summaries?.map((s) => `${s.message}\n\n`) || []),
              ];

              const sectionContent = chunks
                ?.filter((chunk) => !!chunk)
                ?.map((chunk) => {
                  if (/^\d+$|Source \d+/.test(chunk)) {
                    const [sourceNumber] = chunk.match(/(\d+)/) || [];
                    const sourceUrl =
                      query?.searchResults[Number(sourceNumber) - 1]?.url;

                    if (!sourceUrl) {
                      return {
                        type: 'text',
                        text: chunk,
                      };
                    }

                    if (!sourceIndexes[sourceUrl]) {
                      sourceIndexes[sourceUrl] = currentIndex;
                      currentIndex += 1;
                    }

                    return {
                      type: 'citationInline',
                      attrs: {
                        sourceUrl: [sourceUrl],
                      },
                      content: [
                        {
                          type: 'text',
                          text: /^\d+$/.test(chunk)
                            ? `[${sourceIndexes[sourceUrl]}]`
                            : chunk,
                        },
                      ],
                    };
                  }
                  if (/^(?:\d+,?)+$/.test(chunk)) {
                    const sourceNums = chunk.split(',').map(Number);

                    return sourceNums.map((sourceIndex) => {
                      const sourceUrl =
                        query?.searchResults[sourceIndex - 1]?.url;

                      if (!sourceUrl) {
                        return {
                          type: 'text',
                          text: chunk,
                        };
                      }

                      if (!sourceIndexes[sourceUrl]) {
                        sourceIndexes[sourceUrl] = currentIndex;
                        currentIndex += 1;
                      }

                      return {
                        type: 'citationInline',
                        attrs: {
                          sourceUrl: [sourceUrl],
                        },
                        content: [
                          {
                            type: 'text',
                            text: `[${sourceIndexes[sourceUrl]}]`,
                          },
                        ],
                      };
                    });
                  }

                  return {
                    type: 'text',
                    text: chunk,
                  };
                })
                .flatMap((chunk) => chunk);

              return {
                type: 'paragraph',
                content: sectionContent || [],
              };
            });

          return [
            {
              type: 'heading',
              content: [
                {
                  type: 'text',
                  text: title,
                },
              ],
            },
            ...(allSummaries || []),
          ];
        })
        .flatMap((section) => section);

      editor.chain().focus().setContent(content).run();
      setShouldUpdate(false);
    }
  }, [selectedNoteId, editor?.isEmpty, shouldUpdate]);

  return fetchBriefSummary;
};
