import * as Sentry from '@sentry/nextjs';
import { isEmpty, truncate } from 'lodash/fp';
import { selector, selectorFamily, useRecoilValue } from 'recoil';

import { perfEnd, perfStart } from '@/lib/utils/usePerformanceLogger';
import { FetchMethod, TrackableEvent } from '../../lib/trackable';
import { trackUserEvent } from '../../lib/tracking';
import { getPageId } from '../utils/pageId';
import { requestSearchResults } from '../utils/requestSearchResults';
import { pageSizeState } from './pageSize';
import { Page, SearchQueryParams } from './types';
import { searchQueryState } from './waldoQuery';

/**
 * Bing state
 */
const MAX_BING_QUERY_LENGTH = 1850;

const truncateBingQuery = truncate({
  separator: ' ',
  length: MAX_BING_QUERY_LENGTH,
});

export const bingState = selectorFamily<Page, Readonly<SearchQueryParams>>({
  key: 'bingState',
  get:
    ({ searchQuery, page, ...parameters }) =>
    async ({ get }) => {
      if (!searchQuery) {
        return { ads: [], results: [] };
      }

      const limit = get(pageSizeState) || 10;

      const start = (page * limit).toString();
      const trunc = truncateBingQuery(searchQuery);

      perfStart('fetchBingResults');
      const results = await requestSearchResults({
        ...parameters,
        searchQuery: trunc,
        start,
      });
      perfEnd('fetchBingResults');

      if (page === 0) {
        trackUserEvent(
          isEmpty(results) ? TrackableEvent.NO_RESULTS : TrackableEvent.FETCH,
          {
            pageId: getPageId(),
            fetchFrom: FetchMethod.BING,
          },
        );
      }

      return {
        results,
        ads: [],
      };
    },
});

export const bingQueryLengthErrorState = selector<boolean>({
  key: 'bingQueryLengthErrorState',
  get: ({ get }) => {
    const { searchQuery } = get(searchQueryState);
    if (searchQuery.length > MAX_BING_QUERY_LENGTH) {
      Sentry.captureMessage(`Bing query too long: ${searchQuery}`);
      return true;
    }
    return false;
  },
});

export const useShowBingQueryLengthError = (): boolean =>
  useRecoilValue(bingQueryLengthErrorState);
