import { Box, Button, styled, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import classNames from 'classnames';
import FilterIcon from '@/components/atoms/FilterIcon';
import PinButton from '@/components/molecules/PinButton';
import { ExploreFormFilterListItemProps } from './types';
import { useExploreFormFilterListItemProps } from './useExploreFormFilterListItemProps';
import { EXPLORE_FORM_LIST_ITEM_ID } from './constants';
import UpgradeButton from '@/components/molecules/UpgradeButton';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  gap: 1,
  padding: theme.spacing(0.625, 1),
  borderRadius: theme.spacing(0.25),
  cursor: 'pointer',

  '&:hover, &:focus': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  maxWidth: '100%',
}));

const LabelContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(0.25),
  alignItems: 'center',
  overflow: 'hidden',

  '&.disabled': {
    opacity: 0.5,
  },
}));

const EditLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,

  '&:hover': {
    color: theme.palette.primary.light,
    textDecoration: 'underline',
  },
}));

const ExploreFormFilterListItem: FunctionComponent<ExploreFormFilterListItemProps> =
  (props) => {
    const {
      groupName,
      label,
      details,
      value,
      isPinned,
      isActive,
      disabled,
      onToggleActive,
      onApply,
      onEdit,
      onPin,
      onKeyDown,
      onHighlightOption,
      editLabel,
      ...boxProps
    } = useExploreFormFilterListItemProps(props);

    return (
      <Container
        {...boxProps}
        data-component="ExploreFormFilterListItem"
        data-label={label}
        onMouseEnter={() => onToggleActive(true)}
        onMouseLeave={() => onToggleActive(false)}
        onClick={() => onApply({ groupName, label, value })}
        id={EXPLORE_FORM_LIST_ITEM_ID}
        tabIndex={0}
        onKeyDown={onKeyDown}
        onFocus={() => onHighlightOption({ groupName, label, value })}
      >
        <LabelContainer className={classNames({ disabled })}>
          <FilterIcon type={groupName} />
          <Label variant="body1">{label}</Label>
        </LabelContainer>
        <Box className="hoverContainer" display="flex" gap={1} flexShrink={0}>
          {isActive && onEdit && (
            <Button
              variant="text"
              onClick={(e) => {
                e.stopPropagation();
                onEdit();
              }}
            >
              <EditLabel variant="button">{editLabel ?? 'Edit'}</EditLabel>
            </Button>
          )}
          <>
            <PinButton
              isPinned={isPinned}
              onPin={onPin}
              option={{ groupName, label, value }}
              disablePadding
              isVisible={isActive}
            />
            {details}
            {disabled && <UpgradeButton variant="secondary" />}
          </>
        </Box>
      </Container>
    );
  };

export default ExploreFormFilterListItem;
