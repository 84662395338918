import { WaldoAPI } from '@/ext/lib/api';
import { IdeateProject } from '@/ext/types';

export class IdeateAPI extends WaldoAPI {
  private baseUrl = '/v1/project';

  async createIdeateProject(
    title: string,
    data: any = {},
    teamId?: number,
  ): Promise<IdeateProject> {
    return this.request({
      method: 'POST',
      path: this.baseUrl,
      body: {
        title,
        teamId,
        data,
      },
    });
  }

  async deleteIdeateProject(projectId: number): Promise<void> {
    return this.request({
      method: 'DELETE',
      path: `${this.baseUrl}/${projectId}`,
    });
  }

  async getIdeateProject(projectId: number): Promise<IdeateProject | null> {
    return this.request({
      method: 'GET',
      path: `${this.baseUrl}/${projectId}`,
    });
  }

  async getAllIdeateProjects(): Promise<IdeateProject[]> {
    return this.request({
      method: 'GET',
      path: this.baseUrl,
    });
  }

  async updateIdeateProject(
    projectId: number,
    data: Partial<IdeateProject>,
  ): Promise<IdeateProject> {
    return this.request({
      method: 'PUT',
      path: `${this.baseUrl}/${projectId}`,
      body: data,
    });
  }
}
