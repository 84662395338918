import {
  atom,
  Resetter,
  SetterOrUpdater,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';

/**
 * Page Size State
 *
 * - The number of results per page in the search engine
 */
export const pageSizeState = atom<number>({
  key: 'pageSizeState',
  default: 10,
});

export const useSetPageSize = (): SetterOrUpdater<number> =>
  useSetRecoilState(pageSizeState);

export const useResetPageSize = (): Resetter =>
  useResetRecoilState(pageSizeState);
