import { range } from 'lodash/fp';
import {
  atomFamily,
  noWait,
  selector,
  selectorFamily,
  useRecoilCallback,
  useRecoilValue,
  waitForNone,
} from 'recoil';

import { bingState } from './bing';
import { SearchEngine } from './engine';
import { googleState } from './google';
import { Page } from './types';
import {
  numPagesState,
  searchQueryState,
  waldoSearchQueryFiltersState,
} from './waldoQuery';

/**
 * Engine override
 */
export const engineOverrideState = selector<SearchEngine>({
  key: 'engineOverrideState',
  get: ({ get }) => {
    const state = get(searchQueryState);
    const { lens } = get(waldoSearchQueryFiltersState);
    const { engine, searchQuery } = state;
    const numWords = searchQuery
      .split(' ')
      .filter((word) => /\w/.test(word))
      .filter((word) => word !== 'OR').length;
    const useBing = engine === 'bing' || (numWords >= 32 && lens?.length > 0);

    return useBing ? 'bing' : 'google';
  },
});

/**
 * Page state
 */
export const pageState = atomFamily<Page, number>({
  key: 'pageState',
  dangerouslyAllowMutability: true,
  default: selectorFamily<Page, number>({
    key: 'defaultPageState',
    dangerouslyAllowMutability: true,
    get:
      (page: number) =>
      ({ get }) => {
        const state = get(searchQueryState);
        const useBing = get(engineOverrideState) === 'bing';
        const searchQueryPage = { ...state, page };
        let result = useBing
          ? get(bingState(searchQueryPage))
          : get(googleState(searchQueryPage));

        if (result.results.length === 0 && !useBing) {
          result = get(bingState(searchQueryPage));
        }

        return result;
      },
  }),
});

/**
 * Pages state
 *
 * - only returns the pages that are currently available
 */
export const pagesState = selector<Page[]>({
  key: 'pagesState',
  dangerouslyAllowMutability: true,
  get: ({ get }) => {
    const numPages = get(numPagesState);
    const pages = range(0, numPages).map((page) => pageState(page));

    return get(waitForNone(pages))
      .filter(({ state }) => state === 'hasValue')
      .map(({ contents }) => contents);
  },
});

export type IdeatePageParams = {
  query: string;
  sources: string[];
};

/**
 * Ideate page state for background fetching suggested queries
 */
export const ideatePageState = atomFamily<Page, IdeatePageParams>({
  key: 'ideatePageState',
  dangerouslyAllowMutability: true,
  default: selectorFamily<Page, IdeatePageParams>({
    key: 'defaultIdeatePageState',
    dangerouslyAllowMutability: true,
    get:
      ({ query, sources }) =>
      ({ get }) => {
        const siteClause = sources
          .map((source) => `site:${source}`)
          .join(' OR ');

        const useBing = get(engineOverrideState) === 'bing';
        const searchQueryPage = {
          searchQuery: `${query} ${siteClause}`,
          page: 0,
        };
        let result = useBing
          ? get(bingState(searchQueryPage))
          : get(googleState(searchQueryPage));

        if (result.results.length === 0 && !useBing) {
          result = get(bingState(searchQueryPage));
        }

        return result;
      },
  }),
});

export const useGetIdeateQueryResults = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async (params: IdeatePageParams) =>
        snapshot.getPromise(ideatePageState(params)),
  );

/**
 * Page loading state
 */
export const pageLoadingState = selector<boolean>({
  key: 'pageLoadingState',
  get: ({ get }) => get(noWait(pageState(0))).state === 'loading',
});

export const useIsLoading = (): boolean => useRecoilValue(pageLoadingState);
