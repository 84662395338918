import {
  AccessTime,
  InsertDriveFileOutlined,
  Language as Site,
} from '@mui/icons-material';
import { SvgIconProps } from '@mui/material';
import { ElementType, FunctionComponent } from 'react';
import { ContrastCircle } from '@/ext/app/icons/ContrastCircle';
import HashTag from '../Icon/HashTag';
import Language from '../Icon/Language';
import Location from '../Icon/Location';
import LightningBoltOutlined from '../Icon/LightningBoltOutlined';
import { QuickSearch } from '@/components/Icons/QuickSearch';

const ICONS: Record<string, ElementType<SvgIconProps>> = {
  skim: LightningBoltOutlined,
  xray: HashTag,
  lens: ContrastCircle,
  date: AccessTime,
  filetype: InsertDriveFileOutlined,
  language: Language,
  site: Site,
  country: Location,
  quickSearch: QuickSearch,
};

export interface FilterIconProps extends SvgIconProps {
  type: keyof typeof ICONS;
}

const FilterIcon: FunctionComponent<FilterIconProps> = ({ type, ...props }) => {
  const Icon = ICONS[type];

  return Icon ? <Icon fontSize="small" color="disabled" {...props} /> : null;
};

export default FilterIcon;
