/* eslint-disable no-console */

import { useEffect } from 'react';

const received = new Set();

export const recordTime = (eventName: string): void => {
  if (!received.has(eventName)) {
    received.add(eventName);
    performance.mark(`@waldo-web:${eventName}`);
  }
};

export const perfStart = (eventName: string): void => {
  const name = `@waldo-web:${eventName}/start`;
  if (!received.has(name)) {
    received.add(name);
    performance.mark(name);
  }
};

export const perfEnd = (eventName: string): void => {
  const name = `@waldo-web:${eventName}/end`;
  if (!received.has(name)) {
    received.add(name);
    performance.mark(name);
    performance.measure(
      `@waldo-web:${eventName}`,
      `@waldo-web:${eventName}/start`,
      name,
    );
  }
};

export const usePerformanceLogger = (eventName: string, ready?: boolean) => {
  useEffect(() => {
    if (ready === false) {
      return;
    }

    requestAnimationFrame(() => {
      recordTime(eventName);
    });
  }, [eventName, ready]);
};
