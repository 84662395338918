import {
  atom,
  Resetter,
  SetterOrUpdater,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';

/**
 * Allow Typos State
 *
 * - Let Google know to ignore typos with the current `searchQuery`.
 */
export const allowTypoState = atom<boolean>({
  key: 'allowTypoState',
  default: false,
});

export const useSetAllowTypo = (): SetterOrUpdater<boolean> =>
  useSetRecoilState(allowTypoState);

export const useResetAllowTypo = (): Resetter =>
  useResetRecoilState(allowTypoState);
