import { useCallback } from 'react';
import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';

import languageOptions from '../../../utils/languages.json';
import { FilterGroup } from '../types/filterGroup';
import { FilterOption } from '../types/filterOption';

const useLanguageFilterGroupOptions = (): UseInfiniteQueryResult<
  FilterOption[],
  Error
> =>
  useInfiniteQuery(
    ['language-filter-group-options'],
    () =>
      languageOptions.map((data) => ({
        ...data,
        groupName: 'language',
      })),
    {
      getNextPageParam: undefined,
    },
  );

const filter: Omit<FilterGroup, 'query'> = {
  name: 'Language',
  id: 'language',
  tooltip:
    'Filter your results to domains and publications native to a specific language.',
};

export const useLanguageFilterGroup = () => filter;

export const useLanguageFilterGroupWithOptions = (): FilterGroup => {
  const filterGroup = useLanguageFilterGroup();
  const options = useLanguageFilterGroupOptions();
  const getExpandLabel = useCallback((num) => `See all ${num} languages`, []);

  return {
    ...filterGroup,
    getExpandLabel,
    query: options,
  };
};
