import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';

import { sumBy } from 'lodash/fp';
import { ReactNode, useCallback, useMemo } from 'react';
import { uniqBy } from 'lodash';
import { FilterGroup } from '../types/filterGroup';
import { useExploreQuery } from '../exploreQuery';
import { FilterOption } from '../types/filterOption';
import { Lens, LensAPI, keys as lensKeys } from '@/ext/app/state/lenses';
import { useSetDialog } from '@/ext/app/state/dialog';
import LensTooltip from '@/components/molecules/LensTooltip';
import ExploreFormLensFilterListItem from '@/components/organisms/ExploreForm/ExploreFormLensFilterListItem';
import { queryClient } from '@/components/providers/Dependencies';
import { ExploreFormLensFilterListItemProps } from '@/components/organisms/ExploreForm/ExploreFormLensFilterListItem/types';

const lensApi = new LensAPI();

const formatter = (lens: Lens) => ({
  value: lens.lensId,
  label: lens.title,
  groupName: 'lens',
  editLabel: lens.public ? 'Duplicate' : 'Edit',
  FilterComponent: (props: ExploreFormLensFilterListItemProps) => (
    <ExploreFormLensFilterListItem {...props} lens={lens} />
  ),
  DetailsComponent: ({
    children,
    ...props
  }: {
    children?: ReactNode | undefined;
  }) => (
    <LensTooltip lens={lens} {...props}>
      {children}
    </LensTooltip>
  ),
});

const useLensFilterGroupOptions = (
  query: string,
): UseInfiniteQueryResult<FilterOption[], Error> =>
  useInfiniteQuery(
    ['xray-filter-group-options', query],
    async ({ pageParam }) => {
      const lenses = !query
        ? uniqBy(
            [
              ...(await lensApi.suggested()),
              ...(await lensApi.search('', pageParam)),
            ],
            'lensId',
          )
        : await lensApi.search(query, pageParam);
      lenses.forEach((lens) => {
        queryClient.setQueryData(lensKeys.id(lens.lensId), lens);
        queryClient.setQueryData(lensKeys.title(lens.title), lens);
      });
      return lenses.map(formatter);
    },
    {
      getNextPageParam: (lastPage, allPages) =>
        lastPage.length < 10 ? undefined : sumBy('length', allPages),
    },
  );

export const useLensFilterGroup = (): Omit<FilterGroup, 'query'> => {
  const setDialog = useSetDialog();

  return useMemo(
    () => ({
      name: 'Lens',
      id: 'lens',
      tooltip:
        'Groups of trusted sources for higher quality results. Use ours or create your own.',
      multi: true,
      onCreate: () => {
        setDialog({ dialog: 'lens' });
      },
    }),
    [setDialog],
  );
};

export const useLensFilterGroupWithOptions = (): FilterGroup => {
  const filterGroup = useLensFilterGroup();
  const query = useExploreQuery();
  const options = useLensFilterGroupOptions(query);
  const getExpandLabel = useCallback((num) => `See all ${num} lenses`, []);

  return {
    ...filterGroup,
    getExpandLabel,
    query: options,
  };
};
