import { useEffect, useState } from 'react';
import assert from 'assert';

import Pusher, { Channel } from 'pusher-js';

import { ensure } from '../../utils/ensure';
import { useSetConnectionIssues } from './connectionIssues';
import { useSearchQueryId } from './searchResult';

const nlpApi = ensure(process.env.NEXT_PUBLIC_NLP_BASE);
const pusherConfigOverride = {
  clientKey: process.env.NEXT_PUBLIC_PUSHER_CLIENT_KEY,
  cluster: process.env.NEXT_PUBLIC_PUSHER_CLUSTER,
};

const pusherConfig = <const>{
  'https://nlp.waldo.fyi': {
    clientKey: '89f13be0f8768c6a2ac9',
    cluster: 'us2',
  },
  'https://nlp-staging.waldo.fyi': {
    clientKey: '25b2fb5d063683835021',
    cluster: 'us2',
  },
  'https://nlp-dev.waldo.fyi': {
    clientKey: 'b6b6cc37fcbac6a2cab0',
    cluster: 'us2',
  },
  'http://localhost:4000': {
    clientKey: 'b6b6cc37fcbac6a2cab0',
    cluster: 'us2',
  },
};

export const isKeyOfObject = <T>(
  key: string | number | symbol,
  obj: T,
): key is keyof T => key in obj;

const { clientKey, cluster } = isKeyOfObject(nlpApi, pusherConfig)
  ? pusherConfig[nlpApi]
  : pusherConfigOverride;

assert(clientKey, 'NEXT_PUBLIC_PUSHER_CLIENT_KEY is not set');
assert(cluster, 'NEXT_PUBLIC_PUSHER_CLUSTER is not set');

export const pusher = new Pusher(clientKey, {
  cluster,
  forceTLS: true,
});

export const useChannelForId = (searchQueryId: string): Channel | undefined => {
  const [channel, setChannel] = useState<Channel | undefined>();
  const setConnectionIssues = useSetConnectionIssues();

  useEffect(() => {
    const cn = pusher.subscribe(searchQueryId);
    setChannel(cn);

    return () => {
      pusher.unsubscribe(searchQueryId);
    };
  }, [searchQueryId]);

  useEffect(() => {
    const handler = ({
      current,
      previous,
    }: {
      current: string;
      previous: string;
    }) => {
      if (['unavailable', 'failed'].includes(current)) {
        setConnectionIssues(true);
      } else if (['unavailable', 'failed'].includes(previous)) {
        setConnectionIssues(false);
      }
    };

    pusher.connection.bind('state_change', handler);

    return () => {
      pusher.connection.unbind('state_change', handler);
    };
  }, [setConnectionIssues]);

  return channel;
};

export const useChannel = (): Channel | undefined => {
  const searchQueryId = useSearchQueryId();

  return useChannelForId(searchQueryId);
};
