import dayjs from 'dayjs';
import 'dayjs/locale';
import relativeTime from 'dayjs/plugin/relativeTime';
import { uniqBy } from 'lodash';

import * as selectors from './constants';
import { FetchMethod } from '../../lib/trackable';
import { log } from '../../utils/log';
import { SearchResult, SearchResultType } from '../state/types';
import { requestSearchResults } from '../utils/requestSearchResults';
import { getDomain } from '../utils/url';
import { DISPLAYED_DATE_FORMATS } from './constants';

dayjs.extend(relativeTime);

export const getDate = (content: string): string | undefined => {
  const value = content?.replace(' — ', '').trim();

  const parsedDate = dayjs(value, [...DISPLAYED_DATE_FORMATS]);

  if (parsedDate.isValid()) {
    return value;
  }

  const date = dayjs(value);

  return date.isValid() ? value : undefined;
};

/**
 * A helper function for creating a `SearchResult` that will help sanitize props.
 *
 * @returns SearchResult | undefined
 */
export const createSearchResult = (
  searchResult: Partial<SearchResult>,
): SearchResult | undefined => {
  const { title: titleContent, url: originalUrl, ...props } = searchResult;

  if (!originalUrl || !titleContent) {
    log(
      'Could not add search result. No `title` or `url` found.',
      searchResult,
    );
    return undefined;
  }

  let url = originalUrl;

  try {
    const u = new URL(url);
    u.hash = '';

    url = u.toString();
  } catch (e) {
    log('Unable to create url without fragment: ', e);
  }

  const domain = getDomain(url);
  const title = titleContent.trim();

  return {
    domain,
    title,
    url,
    type: SearchResultType.default,
    ...props,
  };
};

/**
 * Get a featured search result if one exists.
 *
 * @param doc the window to get the search result from.
 * @returns SearchResult | undefined
 */
export const getFeaturedSearchResult = (
  doc: Document,
): SearchResult | undefined => {
  const container = doc.querySelector<HTMLElement>(
    selectors.SEARCH_RESULT_FEATURED_SELECTOR,
  );

  if (!container) {
    return undefined;
  }

  const a = container?.querySelector<HTMLAnchorElement>(
    '.g:not(.g-blk) a[ping]',
  );
  const url = a?.href;
  const heading = container?.querySelector<HTMLElement>('[role=heading]');
  const date =
    heading && heading.childNodes.length > 1
      ? getDate((heading.childNodes[1] as HTMLElement)?.innerText)
      : undefined;
  const title = a?.querySelector('h3')?.innerText || heading?.innerText;

  // Build the description out of multiple elements.
  const descriptionContent = [
    // Any datapoints shown which would also be the main excerpt.
    ...Array.from(
      container?.querySelectorAll<HTMLElement>(
        '[data-attrid]:not([data-attrid~=image]) [lang]',
      ) || [],
    ),

    // Tabled data.
    container?.querySelector<HTMLElement>('table')?.parentElement,
  ].filter<HTMLElement>((e): e is HTMLElement => !!e);
  const description = descriptionContent.map((el) => el.innerText).join('');
  const descriptionHTML = descriptionContent.map((el) => el.innerHTML).join('');

  return createSearchResult({
    date,
    description,
    descriptionHTML,
    featured: true,
    title,
    url,
  });
};

export const getVideoResult = (
  container: HTMLElement | null,
): SearchResult | undefined => {
  if (container) {
    const a = container.querySelector<HTMLAnchorElement>('a:not([jsaction])');
    const title = a?.querySelector<HTMLElement>('h3')?.innerText;
    const url = a?.href;

    const content = container.querySelector('.mSA5Bd');

    if (!content) {
      return undefined;
    }

    const descriptionContainer =
      content?.querySelector<HTMLElement>('div:first-child');
    const dateContainer = content?.querySelector<HTMLElement>('div:last-child');

    const description = descriptionContainer?.innerText;
    const descriptionHTML = descriptionContainer?.innerHTML;
    const dateText =
      dateContainer?.querySelector<HTMLElement>('span:last-child')?.innerText;
    const date = dateText ? getDate(dateText) : undefined;

    return createSearchResult({
      date,
      description,
      descriptionHTML,
      title,
      url,
    });
  }

  return undefined;
};

/**
 * Get an array of search results.
 *
 * @param contentWindow the window to get search results from.
 * @returns
 */
export const getSearchResults = (doc: Document): SearchResult[] =>
  Array.from(
    doc.querySelectorAll<HTMLElement>(selectors.SEARCH_RESULTS_SELECTOR),
  )
    .map<SearchResult | undefined>((container) => {
      const a = container.querySelector<HTMLAnchorElement>('a');
      const title = a?.querySelector<HTMLElement>('h3')?.innerText;
      const url = a?.href;
      const excerpt = selectors.EXCERPT_SELECTOR.map((selector) =>
        container.querySelector<HTMLElement>(selector),
      ).find((result) => !!result);

      if (!excerpt) {
        return getVideoResult(container);
      }

      let date: string | undefined;
      let description: string | undefined;
      let descriptionHTML: string | undefined;

      // In some rare cases, a date is added below the excerpt
      // like with a result in the search "monk fruit" or "how to do a deadlift".
      const bottomDate = container.querySelector<HTMLElement>('.IsZvec .uo4vr');
      if (bottomDate) {
        date = getDate(bottomDate?.innerText);
        description = excerpt?.innerHTML;
      } else {
        const hasChunks =
          excerpt &&
          excerpt.childNodes.length > 1 &&
          // Sometimes Google uses a table to show formatted data for a result.
          !(excerpt.childNodes[0] instanceof HTMLTableElement);

        date = hasChunks
          ? getDate((excerpt.childNodes[0] as HTMLElement)?.innerText)
          : undefined;

        const isVideo =
          hasChunks && excerpt.childNodes[0] instanceof HTMLAnchorElement;

        if (date) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const node = excerpt?.childNodes[1] as any;
          description = (node as Text).wholeText ?? node?.innerText;
          descriptionHTML =
            node instanceof Text ? description : node?.innerHTML;
        } else if (isVideo) {
          const element = excerpt?.childNodes[1] as HTMLElement;
          description = element?.innerText;
          descriptionHTML = element?.innerHTML;
        } else {
          description = excerpt?.innerText;
          descriptionHTML = excerpt?.innerHTML;
        }
      }

      return createSearchResult({
        date,
        description,
        descriptionHTML,
        title,
        url,
      });
    })
    .filter<SearchResult>((r): r is SearchResult => !!r);

/**
 * Determine if we actually have any search results to get.
 *
 * @returns boolean
 */
export const canGetSearchResults = (doc: Document): boolean => {
  // Only use fallback (API) if we have a result-stats div (that means that
  // Google is not showing the "Your search ... did not match any documents." message).
  const resultStatsDiv = doc.body.querySelector<HTMLElement>('#result-stats');

  // Sometimes we can get a #result-stats like "About 0 results (0.25 seconds)".
  const zeroResults = /\s0\s/.test(resultStatsDiv?.innerText || '');

  return !!(resultStatsDiv && !zeroResults);
};

/**
 * Get a new array of search results that are unique by `url`.
 *
 * @param searchResults an array of search results to filter.
 * @returns SearchResult[]
 */
export const getUniqueSearchResults = (
  searchResults: SearchResult[],
): SearchResult[] => uniqBy(searchResults, 'url');

/**
 * A unique list of search results.
 *
 * @param contentWindow the window to get search results from.
 * @returns Promise<SearchResult[]>.
 */
export const getAllSearchResults = async (
  searchQuery: string,
  doc: Document,
): Promise<{ method: FetchMethod; searchResults: SearchResult[] }> => {
  dayjs.locale(navigator.language);

  const featured = getFeaturedSearchResult(doc);
  let searchResults = getSearchResults(doc);
  let method = FetchMethod.GOOGLE;

  if (canGetSearchResults(doc) && !searchResults.length) {
    const results = await requestSearchResults({ searchQuery });

    method = FetchMethod.BING;
    searchResults = results;
  }

  return {
    method,
    searchResults: getUniqueSearchResults(
      featured ? [featured, ...searchResults] : searchResults,
    ),
  };
};

/**
 * A unique list of ad results
 *
 * @param contentWindow the window to get search results from.
 * @returns Promise<SearchResult[]>.
 */
export const getAdSearchResults = (doc: Document): SearchResult[] => {
  const resultAds = Array.from(
    doc.querySelectorAll<HTMLAnchorElement>('a[data-pcu]'),
  ).map((e) => ({
    url: e.dataset.pcu || e.href,
    title: e.innerText.trim(),
    domain: getDomain(e.dataset.pcu || e.href),
    type: SearchResultType.default,
  }));

  const cardAds = Array.from(
    doc.querySelectorAll<HTMLAnchorElement>('a.pla-unit-title-link'),
  ).map((e) => ({
    url: e.href,
    title: e.innerText.trim(),
    domain: getDomain(e.href),
    type: SearchResultType.default,
  }));

  return [...resultAds, ...cardAds];
};
