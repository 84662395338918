import {
  QueryObserverOptions,
  QueryKey,
  UseQueryResult,
  useQuery,
} from 'react-query';

import { HttpAdapter, WaldoAPI } from '@/ext/lib/api';

import { ensure } from '@/ext/utils/ensure';
import { Entity } from './types';

const NLP_BASE = ensure(process.env.NEXT_PUBLIC_NLP_BASE);

export const QUERY_KEY = 'keywordSearch';

export interface KeywordSearchResult {
  keyword: string;
  mentions: number;
  pages: number;
}

export class KeywordsAPI extends WaldoAPI {
  constructor(adapter = new HttpAdapter(NLP_BASE)) {
    super(adapter);
  }

  public async keywords(query: {
    searchQueryId: string;
    match?: string;
    limit?: number;
  }): Promise<Entity[]> {
    const result = await this.request<KeywordSearchResult[]>({
      path: '/keywords',
      method: 'GET',
      query,
    });

    return result.map(({ keyword: value, pages, mentions }) => ({
      value,
      pages,
      mentions,
    }));
  }
}

const api = new KeywordsAPI();

export const getKeywordSearchQueryKey = (
  searchQueryId: string,
  needle?: string,
  limit?: number,
): QueryKey =>
  [QUERY_KEY, searchQueryId, needle, limit].filter((v) => v !== undefined);

export const useKeywordSearch = (
  searchQueryId: string,
  match?: string,
  limit?: number,
  options?: QueryObserverOptions<Entity[], Error>,
): UseQueryResult<Entity[], Error> =>
  useQuery(
    getKeywordSearchQueryKey(searchQueryId, match, limit),
    () => api.keywords({ searchQueryId, match, limit }),
    options,
  );
