import { atom, useRecoilValue } from 'recoil';
import * as uuid from 'uuid';

export type ValueType = string;

export const sessionIdState = atom<ValueType>({
  key: 'sessionId',
  default: uuid.v4(),
});

export const useSessionId = (): ValueType => useRecoilValue(sessionIdState);
