import { isEmpty, times } from 'lodash/fp';
import {
  atom,
  noWait,
  selector,
  selectorFamily,
  SetterOrUpdater,
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
  waitForAll,
} from 'recoil';
import { fetchIframeWindow } from '@/lib/utils/fetchIframeWindow';

import { FetchMethod, TrackableEvent } from '../../lib/trackable';
import { trackUserEvent } from '../../lib/tracking';
import { CAPTCHA_SELECTOR, GOOGLE_ROOT_URL } from '../google/constants';
import {
  getAdSearchResults,
  getAllSearchResults,
} from '../google/searchResults';
import { getUpdatedUrl } from '../google/utils';
import { getPageId } from '../utils/pageId';
import { sendMessage } from '../utils/sendBackgroundMessage';
import { allowTypoState } from './allowTypo';
import { pageSizeState } from './pageSize';
import { Page, SearchQueryParams } from './types';
import { numPagesState, searchQueryState } from './waldoQuery';
import { perfEnd, perfStart } from '@/lib/utils/usePerformanceLogger';

/**
 * Base Google URL which we construct off of
 */
const BASE_GOOGLE_URL = 'https://www.google.com/search?num=10';

export const googleUrlState = selectorFamily<
  string,
  Readonly<SearchQueryParams>
>({
  key: 'googleUrlState',
  get:
    (options) =>
    ({ get }) => {
      const { searchQuery, page, ...parameters } = options;

      const allowTypo = get(allowTypoState) ? '1' : undefined;
      const pageSize = get(pageSizeState);
      const start = page * pageSize;

      return getUpdatedUrl(BASE_GOOGLE_URL, {
        searchQuery,
        allowTypo,
        pageSize: pageSize.toString(),
        start: start.toString(),
        ...parameters,
      });
    },
});

export const currentGoogleUrlState = selector<string>({
  key: 'currentGoogleUrlState',
  get: ({ get }) => {
    const state = get(searchQueryState);
    const options = { ...state, page: 0 };
    return get(googleUrlState(options));
  },
});

const TIMEOUT = 10000;
export const googleHTMLState = selectorFamily<
  string,
  Readonly<SearchQueryParams>
>({
  key: 'googleHTMLState',
  get:
    (options) =>
    async ({ get }) => {
      const { searchQuery } = options;

      if (isEmpty(searchQuery)) {
        return '';
      }

      const src = get(googleUrlState(options));
      try {
        perfStart('fetchGoogleResults');
        return (
          await sendMessage<string>(
            {
              type: 'FETCH_PAGE',
              payload: { url: src },
            },
            TIMEOUT,
          )
        )
          .replace(/src="\//g, 'src="https://www.google.com/')
          .replace(/href="\//g, 'href="https://www.google.com/');
      } catch (e) {
        return '';
      } finally {
        perfEnd('fetchGoogleResults');
      }
    },
});

const googleWindowState = selectorFamily<Window, Readonly<SearchQueryParams>>({
  key: 'googleWindowState',
  dangerouslyAllowMutability: true,
  get:
    (options) =>
    async ({ get }) => {
      const html = get(googleHTMLState(options));

      perfStart('fetchGoogleIframe');
      const window = await fetchIframeWindow(html, GOOGLE_ROOT_URL);
      perfEnd('fetchGoogleIframe');

      return window;
    },
});

/**
 * Content Window state
 */
export const contentWindowState = selector<Window | null>({
  key: 'contentWindowState',
  dangerouslyAllowMutability: true,
  get: ({ get }) => {
    const state = get(searchQueryState);
    if (state.engine !== 'google') {
      return null;
    }
    const searchQueryPage = { ...state, page: 0 };
    const loadable = get(noWait(googleWindowState(searchQueryPage)));
    return loadable.state === 'hasValue' ? loadable.contents : null;
  },
});

const googleDocumentState = selectorFamily<
  Document | undefined,
  Readonly<SearchQueryParams>
>({
  key: 'googleDocumentState',
  get:
    (options) =>
    ({ get }) => {
      const html = get(googleHTMLState(options));

      const parser = new DOMParser();

      if (html) {
        perfStart('parseGoogleResults');
        const result = parser.parseFromString(html, 'text/html');
        perfEnd('parseGoogleResults');
        return result;
      }

      return undefined;
    },
});

const activeDocumentState = selector<Document | undefined>({
  key: 'activeDocumentState',
  get: ({ get }) => {
    const searchQuery = get(searchQueryState);

    if (searchQuery.engine !== 'google') {
      return undefined;
    }

    const contentWindow = get(noWait(contentWindowState)).valueMaybe();
    const document = get(
      noWait(googleDocumentState({ page: 0, ...searchQuery })),
    ).valueMaybe();

    return contentWindow?.document || document;
  },
});

export const useActiveDocument = () =>
  useRecoilValueLoadable(activeDocumentState).valueMaybe();

export const googleState = selectorFamily<Page, Readonly<SearchQueryParams>>({
  key: 'googleState',
  get:
    (options) =>
    async ({ get }) => {
      const { searchQuery, page } = options;
      const htmlDoc = get(googleDocumentState(options));

      if (!htmlDoc) {
        return { results: [], ads: [] };
      }

      const { searchResults } = await getAllSearchResults(searchQuery, htmlDoc);

      const adResults = getAdSearchResults(htmlDoc);

      if (page === 0) {
        trackUserEvent(
          isEmpty(searchResults)
            ? TrackableEvent.NO_RESULTS
            : TrackableEvent.FETCH,
          {
            pageId: getPageId(),
            fetchFrom: FetchMethod.GOOGLE_IFRAME,
          },
        );
      }

      return { results: searchResults, ads: adResults };
    },
});

const isShowingGoogleElementState = atom<boolean>({
  key: 'isShowingGoogleElementState',
  default: false,
});

export const useIsShowingGoogleElement = (): boolean =>
  useRecoilValue(isShowingGoogleElementState);

export const useSetIsShowingGoogleElement = (): SetterOrUpdater<boolean> =>
  useSetRecoilState(isShowingGoogleElementState);

const requiresCaptchaState = selector<boolean>({
  key: 'googleRequiresCaptchaState',
  get: async ({ get }) => {
    const searchQuery = get(searchQueryState);

    if (searchQuery.engine !== 'google') {
      return false;
    }

    const numPages = get(numPagesState);
    const documentStates = times(
      (page) => googleDocumentState({ ...searchQuery, page }),
      numPages,
    );
    const documents = get(waitForAll(documentStates));

    return documents.some((document) =>
      document?.querySelector(CAPTCHA_SELECTOR),
    );
  },
});

export const useRequiresCaptcha = (): boolean =>
  useRecoilValue(requiresCaptchaState);
