import { useInfiniteQuery } from 'react-query';
import { useCallback } from 'react';
import { FilterGroup } from '../types/filterGroup';
import { useUser } from '../../user';
import { SubscriptionType } from '@/ext/types';

export const SKIM_GROUP_NAME = 'skim';

export const SKIM_FILTER_OPTIONS = [
  {
    label: 'All Text',
    value: 'all',
    groupName: SKIM_GROUP_NAME,
  },
  {
    label: 'Datapoints',
    value: 'datapoints',
    groupName: SKIM_GROUP_NAME,
  },
  {
    label: 'Dates',
    value: 'dates',
    groupName: SKIM_GROUP_NAME,
  },
  {
    label: 'Emails',
    value: 'emails',
    groupName: SKIM_GROUP_NAME,
    restrictedTiers: [SubscriptionType.lite, SubscriptionType.pro],
  },
  {
    label: 'Phones',
    value: 'phones',
    groupName: SKIM_GROUP_NAME,
    restrictedTiers: [SubscriptionType.lite, SubscriptionType.pro],
  },
  {
    label: 'Social Media',
    value: 'social media',
    groupName: SKIM_GROUP_NAME,
    restrictedTiers: [SubscriptionType.lite, SubscriptionType.pro],
  },
  {
    label: 'Quotes',
    value: 'quotes',
    groupName: SKIM_GROUP_NAME,
  },
  {
    label: 'Links',
    value: 'links',
    groupName: SKIM_GROUP_NAME,
  },
];

const filter: Omit<FilterGroup, 'query'> = {
  name: 'Skim',
  id: SKIM_GROUP_NAME,
  tooltip:
    'Use Skim to preview the text inside your search results before opening them.',
};

export const useSkimFilterGroup = () => filter;

export const useSkimFilterGroupWithOptions = (): FilterGroup => {
  const filterGroup = useSkimFilterGroup();
  const user = useUser();
  const options = useInfiniteQuery(
    'skimFilterOptions',
    () =>
      SKIM_FILTER_OPTIONS.map((option) => ({
        ...option,
      })),
    {
      enabled: !!user,
    },
  );

  const getExpandLabel = useCallback(
    (num) => `See all ${num} skim options`,
    [],
  );

  return {
    ...filterGroup,
    getExpandLabel,
    query: options,
    disabled: user?.subscription === SubscriptionType.lite,
  };
};
