import { captureException } from '@sentry/nextjs';

import { log } from '../../utils/log';

/**
 * Get just a domain / hostname from a URL.
 * @param url string
 * @returns string
 */
export const getDomain = (url: string): string => {
  let domain;

  try {
    domain = new URL(url).hostname;
  } catch (e) {
    log('Unable to create domain: ', e);
    domain = url;
  }

  return domain.replace(/^www\./, '');
};

/**
 * Return a URL without a protocol or trailing slash (more human readable).
 *
 * @param url string
 *
 * @returns string
 */
export const getCleanedURLPath = (value: string): string => {
  let newValue;

  try {
    const url = new URL(value);
    const empties = ['/', '//'];

    newValue = `${getDomain(value)}${
      !empties.includes(url.pathname) ? url.pathname : ''
    }`;
  } catch (e) {
    newValue = value;
  }

  return newValue;
};

// https://chromestatus.com/feature/4733392803332096
export const getScrollToTextFragmentUrl = ({
  matchStart,
  matchEnd,
  url,
}: {
  matchStart: string;
  matchEnd: string;
  url: string;
}): string => {
  const text = [matchStart, matchEnd]
    .filter((v) => !!v)
    .map(encodeURIComponent)
    .join(',');

  return `${url}#:~:text=${text}`;
};

/**
 * Return a given string as a valid url by making sure
 * a protocol is defined.
 *
 * @param value string
 * @returns string
 */
export const getValidURL = (value: string): string => {
  let finalValue = value;

  if (finalValue.search(/https?:\/\//) === -1) {
    finalValue = `http://${finalValue}`;
  }

  return finalValue;
};

/**
 * Returns if a given string is a valid url
 *
 * @param value string
 * @returns boolean
 */
export const isValid = (value: string, sendError = false): boolean => {
  let url;

  if (!value) {
    return false;
  }

  const isRelativePath = value.indexOf('/') === 0;

  try {
    url = new URL(value, isRelativePath ? window.location.origin : undefined);
  } catch (_) {
    if (sendError) {
      captureException(
        `Attempted to show search result with invalid url: ${url}`,
      );
    }

    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};
