import { useMutation, useQuery } from 'react-query';
import { pick, reject } from 'lodash/fp';

import { StorageAPI } from '@/rpc/storage';
import { queryClient } from '@/components/providers/Dependencies';
import { FilterOptionId } from './types/filterOption';

const storage = new StorageAPI();

const resolvePin = (
  pins: FilterOptionId[],
  option: FilterOptionId,
  value: boolean,
) =>
  value
    ? [...pins, option]
    : reject<FilterOptionId>(pick(['value', 'groupName'], option), pins);

export const usePinFilterOption = () =>
  useMutation(
    async (data: {
      option: FilterOptionId;
      value: boolean;
    }): Promise<FilterOptionId[]> => {
      const { option, value } = data;
      const pins = await storage.get<FilterOptionId[]>('pinnedFilters');
      const pinnedFilters = resolvePin(pins ?? [], option, value);
      await storage.set({ pinnedFilters });
      return pinnedFilters;
    },
    {
      onMutate(data) {
        const { option, value } = data;
        const pins =
          queryClient.getQueryData<FilterOptionId[]>('pinnedFilters');
        const pinnedFilters = resolvePin(pins ?? [], option, value);
        queryClient.setQueryData('pinnedFilters', pinnedFilters);
      },
      onError() {
        queryClient.invalidateQueries('pinnedFilters');
      },
      useErrorBoundary: true,
    },
  );

/**
 * Update Pinned Filters
 *
 * - eventual use for reordering
 * - this will override pins saved in another tab if we're not careful
 */
export const useUpdatePinnedFilters = () =>
  useMutation(
    async (pinnedFilters: FilterOptionId[]): Promise<FilterOptionId[]> => {
      await storage.set({ pinnedFilters });
      return pinnedFilters;
    },
    {
      onMutate(pinnedFilters) {
        queryClient.setQueryData('pinnedFilters', pinnedFilters);
      },
      onError() {
        queryClient.invalidateQueries('pinnedFilters');
      },
      useErrorBoundary: true,
    },
  );

export const usePinnedFilterOptions = () =>
  useQuery(
    'pinnedFilters',
    async () => {
      const pins = await storage.get<FilterOptionId[]>('pinnedFilters');
      return pins ?? [];
    },
    { useErrorBoundary: true },
  );
