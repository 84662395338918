import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';
import wordsToNumbers from 'words-to-numbers';
import dayjs from 'dayjs';

import { capitalize, startsWith, uniqBy } from 'lodash/fp';
import { useCallback } from 'react';
import { FilterGroup } from '../types/filterGroup';
import { useExploreQuery } from '../exploreQuery';
import { FilterOption } from '../types/filterOption';
import { converWordToInteger, writtenNumbers } from '@/ext/lib/number';
import { pluralize } from '@/lib/utils/pluralize';

export const DATE_GROUP_NAME = 'date';

// TODO: Revisit this to remove the duplication and potentially invalidate
// options that are not valid numbers
const getOptions = (query: string): FilterOption[] => {
  const year = dayjs().year();
  const lastYear = dayjs().subtract(1, 'year').year();

  /**
   * first part of regex will get the first word
   * second part will get the middle word as long as doesnt start with m, y, w or d
   * third part will get the last word
   */
  const reg = /^(?<prev>\w+) ?(?<num>\b(?!m|y|w|d)\w*\b)? ?(?<unit>\w+)?/;
  const groups = query.match(reg)?.groups;
  const prev = groups?.prev || '';
  const start = ['last', 'past', 'previous'].find(startsWith(prev));
  const parsedNum = groups?.num && converWordToInteger(groups.num);

  const fmt = (num: number | null, unit: string): string[] => {
    const p1 = start || 'past';
    const p2 = groups?.num || (groups?.unit ? null : num);
    const p3 = pluralize(unit, parsedNum || (groups?.unit ? null : num) || 1);

    const suggestions = writtenNumbers
      .filter((n) => p2 && n.startsWith(p2?.toString() || ''))
      .map((option) => {
        const count = parseInt(wordsToNumbers(option)?.toString() || '1', 10);
        return [p1, option, pluralize(unit, count)].filter(Boolean).join(' ');
      });

    if (suggestions.length) {
      return suggestions;
    }

    return [[p1, p2, p3].filter(Boolean).join(' ')];
  };

  const lastDate = (num: number | null, unit: string): FilterOption[] => {
    const ls = fmt(num, unit);
    return ls.map((value) => ({
      label: capitalize(value),
      value,
      groupName: 'date',
    }));
  };

  const options = uniqBy(
    ({ value }) => value,
    [
      ...lastDate(null, 'day'),
      ...lastDate(7, 'day'),
      ...lastDate(2, 'week'),
      ...lastDate(3, 'month'),
      ...lastDate(null, 'year'),
      ...lastDate(2, 'year'),
      ...lastDate(5, 'year'),
      {
        label: '2020',
        value: '2020',
        groupName: 'date',
      },
      {
        label: 'From 2020 to 2022',
        value: 'from 2020 to 2022',
        groupName: 'date',
      },
      {
        label: 'From 1/1/2020 to 4/31/2022',
        value: 'from 1/1/2020 to 4/31/2022',
        groupName: 'date',
      },
      {
        label: 'From 1/2020 to 4/2022',
        value: 'from 1/2020 to 4/2022',
        groupName: 'date',
      },
      {
        label: 'Before 2020',
        value: 'before 2020',
        groupName: 'date',
      },
      {
        label: 'After 2020',
        value: 'after 2020',
        groupName: 'date',
      },
      {
        label: '2015 to 2020',
        value: '2015 to 2020',
        groupName: 'date',
      },
      {
        label: '2018 - 2020',
        value: '2018 - 2020',
        groupName: 'date',
      },
    ],
  );

  return options.length
    ? options
    : [
        {
          label: query,
          value: query,
          groupName: 'date',
        },
      ];
};

export const useDateFilterGroupOptions = (
  query: string,
): UseInfiniteQueryResult<FilterOption[], Error> =>
  useInfiniteQuery(['dateOptions', query], () => getOptions(query), {
    getNextPageParam: undefined,
  });

const filter: Omit<FilterGroup, 'query'> = {
  name: 'Date',
  id: 'date',
  tooltip: 'Easily filter by any date range.',
};

export const useDateFilterGroup = () => filter;

export const useDateFilterGroupWithOptions = (): FilterGroup => {
  const query = useExploreQuery();

  const filterGroup = useDateFilterGroup();
  const options = useDateFilterGroupOptions(query);
  const getExpandLabel = useCallback(
    (num) => `See all ${num} date suggestions`,
    [],
  );

  return {
    ...filterGroup,
    getExpandLabel,
    query: options,
  };
};
