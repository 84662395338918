import { Box, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { ExploreFormLensFilterListItemProps } from './types';
import { useExploreFormLensFilterListItemProps } from './useExploreFormLensFilterListItemProps';
import ExploreFormFilterListItem from '../ExploreFormFilterListItem';
import LensTooltip from '@/components/molecules/LensTooltip';

const ExploreFormLensFilterListItem: FunctionComponent<ExploreFormLensFilterListItemProps> =
  (props) => {
    const { groupName, label, value, teamName, lens, onEdit, ...rest } =
      useExploreFormLensFilterListItemProps(props);

    if (!lens) {
      return null;
    }

    return (
      <LensTooltip lens={lens}>
        <ExploreFormFilterListItem
          {...rest}
          groupName={groupName}
          label={label}
          value={value}
          onEdit={onEdit}
          editLabel={lens.public ? 'Duplicate' : 'Edit'}
          details={
            <Box display="flex" alignItems="center" gap={1}>
              <Typography variant="body2" color="text.disabled">
                {teamName}
              </Typography>
            </Box>
          }
        />
      </LensTooltip>
    );
  };

export default ExploreFormLensFilterListItem;
