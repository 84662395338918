import {
  filter,
  flow,
  keyBy,
  keys,
  mapValues,
  orderBy,
  uniqBy,
} from 'lodash/fp';
import {
  atom,
  atomFamily,
  selector,
  useRecoilCallback,
  useRecoilValue,
} from 'recoil';

import {
  resultsState,
  searchQueryIdState,
  useSearchQueryId,
} from './searchResult';
import { SearchResult } from './types';

export interface TopLink {
  url: string;
  mentions: number;
  pages: string[];
}

type MetadataValueType = SearchResult[];

const topLinkMentionsState = atomFamily<TopLink | undefined, string>({
  key: 'topLinkMentions',
  default: undefined,
});

const allTopLinkMentionsState = atom<Record<string, number>>({
  key: 'allTopLinkMentions',
  default: {},
});

export const useTopLinkMentions = (url: string): TopLink | undefined =>
  useRecoilValue(topLinkMentionsState(url));

const topLinksToDictionary = flow(
  filter(({ mentions }) => mentions > 1),
  keyBy<TopLink>('url'),
  mapValues('mentions'),
);

export const topLinksState = atomFamily<MetadataValueType, string>({
  key: 'topLinks',
  default: [],
});

export const useTopLinks = (): MetadataValueType =>
  useRecoilValue(topLinksState(useSearchQueryId()));

export const useSetTopLinks = (): ((links: TopLink[]) => void) =>
  useRecoilCallback(
    ({ set }) =>
      (links: TopLink[]) => {
        links.forEach((link) => {
          set(topLinkMentionsState(link.url), link);
        });
        set(allTopLinkMentionsState, topLinksToDictionary(links));
      },
    [],
  );

export const useAddTopLink = (): ((topLink: SearchResult) => void) =>
  useRecoilCallback(
    ({ set, snapshot }) =>
      (topLink: SearchResult) => {
        const searchResults = snapshot.getLoadable(resultsState).getValue();
        const searchQueryId = snapshot
          .getLoadable(searchQueryIdState)
          .getValue();
        if (!searchResults.find(({ url }) => url === topLink.url)) {
          set(topLinksState(searchQueryId), (topLinks) =>
            uniqBy('url', [...topLinks, topLink]),
          );
        }
      },
    [],
  );

export const orderedTopLinksSelector = selector({
  key: 'orderedTopLinks',
  get: ({ get }) => {
    const searchQueryId = get(searchQueryIdState);
    const topLinks = get(topLinksState(searchQueryId));
    const allTopLinkMentions = get(allTopLinkMentionsState);

    return orderBy(({ url }) => allTopLinkMentions[url], 'desc', topLinks);
  },
});

export const useOrderedTopLinks = (): SearchResult[] =>
  useRecoilValue(orderedTopLinksSelector);

export const topLinksUrlsSelector = selector({
  key: 'topLinksUrls',
  get: ({ get }) => keys(get(allTopLinkMentionsState)),
});

// TODO: don't think this belongs in this file.
export const allResultsSelector = selector({
  key: 'allResults',
  get: ({ get }) => [...get(resultsState), ...get(orderedTopLinksSelector)],
});

export const useAllResults = (): SearchResult[] =>
  useRecoilValue(allResultsSelector);
