/* eslint-disable no-nested-ternary */

import dayjs from 'dayjs';
import { flow, replace } from 'lodash/fp';
import superagent from 'superagent';

import { DATE_FORMAT } from '../google/constants';
import { QueryStringValues } from '../google/utils';
import { SearchResult } from '../state/types';

const ENDPOINT_URL = `${process.env.NEXT_PUBLIC_NLP_BASE}/fallback`;
const BING_DATE_FORMAT = 'YYYY-MM-DD';

/**
 * Convert Google language code to Bing language code
 *
 * Bing and Google use different language codes. This method converts
 * 'lang_zh-CN' to 'zh-hans'.
 */
const langGoogleToBing: (language: string) => string = flow(
  (x) => x.slice(5),
  replace('-CN', '-hans'),
  replace('-TW', '-hant'),
);

export const requestSearchResults = async ({
  searchQuery: query,
  start: skip,
  language,
  from,
  to,
  country,
  ...values
}: QueryStringValues): Promise<SearchResult[]> => {
  const { body } = await superagent
    .post(ENDPOINT_URL)
    .send({
      query,
      skip: skip ? parseInt(skip, 10) : undefined,
      limit: 10,
      language: language ? langGoogleToBing(language) : undefined,
      country: country ? country.slice(7) : undefined,
      from: from
        ? dayjs(from, DATE_FORMAT).format(BING_DATE_FORMAT)
        : undefined,
      to: to
        ? dayjs(to, DATE_FORMAT).format(BING_DATE_FORMAT)
        : from
        ? dayjs().format(BING_DATE_FORMAT)
        : undefined,
      ...values,
    })
    .withCredentials();

  return body;
};
