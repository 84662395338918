import { forwardRef } from 'react';

import { Typography, TypographyProps, styled } from '@mui/material';
import classNames from 'classnames';

export interface HotkeyBoxProps extends Omit<TypographyProps, 'color'> {
  color?: 'default' | 'medium' | 'primary' | 'secondary' | 'tertiary';
  size?: 'small' | 'large';
  value: string;
}

const Hotkey = styled(Typography)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.fill?.disabled,
  borderRadius: 3,
  color: theme.palette.text.disabled,
  display: 'flex',
  flex: '0 0 auto',
  height: theme.spacing(1.25),
  justifyContent: 'center',
  minWidth: theme.spacing(1.25),
  padding: theme.spacing(0, 0.25),
  textTransform: 'capitalize',
  userSelect: 'none',

  '&.large': {
    height: theme.spacing(1.75),
    minWidth: theme.spacing(2),
    padding: theme.spacing(0.125, 0.375),
  },

  '&.medium': {
    backgroundColor: theme.palette.fill?.medium,
  },

  '&.primary': {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },

  '&.secondary': {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.dark,
  },

  '&.tertiary': {
    color: theme.palette.tertiary?.contrastText,
    backgroundColor: theme.palette.tertiary?.main,
  },
}));

const HotkeyBox = forwardRef<HTMLSpanElement, HotkeyBoxProps>(
  (
    { className = '', color = 'default', size = 'small', value, ...props },
    ref,
  ) => (
    <Hotkey
      className={classNames(color, size, { [className]: !!className })}
      ref={ref}
      variant="overline"
      {...props}
    >
      {value}
    </Hotkey>
  ),
);

export default HotkeyBox;
