import {
  atom,
  atomFamily,
  selector,
  selectorFamily,
  useRecoilCallback,
  useRecoilValue,
  useRecoilValueLoadable,
} from 'recoil';
import superagent from 'superagent';
import * as Sentry from '@sentry/nextjs';

import flatMap from 'lodash/flatMap';
import { queryClient } from '@/components/providers/Dependencies';
import { resultsState, useSearchQueryId } from '@/ext/app/state/searchResult';
import { useWaldoSearchQuery } from '@/ext/app/state/waldoQuery';
import { textFromHTML } from './utils';
import { SearchResult } from '@/ext/app/state/types';
import { IdeatePageParams, ideatePageState } from '@/ext/app/state/page';
import { selectedIdeateProjectState, summaryReadyState } from './datapoints';

const SUMMARY_ENDPOINT_URL = `${process.env.NEXT_PUBLIC_NLP_BASE}/summary`;

export const searchSummaryRawState = atomFamily<Record<number, string>, string>(
  {
    key: 'searchSummaryState',
    default: {},
  },
);

export const searchSummaryTransformedState = selectorFamily({
  key: 'searchSummaryTransformedState',
  get:
    (sqid: string) =>
    ({ get }) => {
      const raw = Object.values(get(searchSummaryRawState(sqid))).join('');
      const [content] = raw.split('</>');

      return {
        content,
      };
    },
});

type SearchSummaryParams = {
  query: string;
  results: Readonly<SearchResult>[];
  sqid: string;
  projectId?: number;
};

export const searchSummary = selectorFamily<void, SearchSummaryParams>({
  key: 'searchSummary',
  get:
    ({ query, results, sqid, projectId }) =>
    async () => {
      const excerpts = results.map(
        ({ description, descriptionHTML }) =>
          description || textFromHTML(descriptionHTML || ''),
      );

      if (!excerpts.length) {
        return;
      }

      const payload = { excerpts, query, sqid, projectId };

      await queryClient
        .fetchQuery({
          queryKey: ['searchSummary', payload],
          staleTime: Infinity,
          queryFn: () =>
            superagent
              .post(SUMMARY_ENDPOINT_URL)
              .send(payload)
              .withCredentials(),
        })
        .catch((err) => {
          Sentry.captureException(err);
          throw err;
        });
    },
});

export const useStreamedSearchSummary = () => {
  const sqid = useSearchQueryId();
  const results = useRecoilValue(resultsState);
  const query = useWaldoSearchQuery();

  useRecoilValueLoadable(searchSummary({ query, results, sqid }));
  return useRecoilValue(searchSummaryTransformedState(sqid));
};

export type SummaryResponse = {
  content: string;
};

export const ideateSummaryIdsState = atom<string[]>({
  key: 'ideateSummaryIdsState',
  default: [],
});

export const ideateSummaryState = selectorFamily<
  void,
  IdeatePageParams & { searchQueryId: string; projectId: number }
>({
  key: 'ideateSummaryState',
  get:
    ({ searchQueryId, query, sources, projectId }) =>
    async ({ get }) => {
      const page = get(ideatePageState({ query, sources }));
      const { results } = page;

      get(searchSummary({ query, results, sqid: searchQueryId, projectId }));
    },
});

const ideateSummariesReadyState = selector({
  key: 'useIdeateSummariesReady',
  get: ({ get }) => {
    const ids = get(ideateSummaryIdsState);
    const readyStates = ids.map((id) => get(summaryReadyState(id)));
    const project = get(selectedIdeateProjectState);
    const summaries = flatMap(
      project?.data.sections,
      (section) =>
        section.queries?.map(
          (query: { summary?: { content: string } }) => query.summary,
        ) || [],
    );

    return (
      (ids.length > 0 && readyStates.every((state) => state)) ||
      summaries.some((summary) => !!summary)
    );
  },
});

export const useIdeateSummariesReady = () =>
  useRecoilValue(ideateSummariesReadyState);

export const useFetchSearchSummary = () =>
  useRecoilCallback(
    ({ snapshot, set, refresh }) =>
      async (params: {
        searchQueryId: string;
        query: string;
        sources: string[];
        projectId: number;
      }) => {
        refresh(ideateSummaryState(params)); // Need to refresh state to re-fetch when error occurs
        set(ideateSummaryIdsState, (prev) => [...prev, params.searchQueryId]);

        return snapshot.getPromise(ideateSummaryState(params));
      },
  );
