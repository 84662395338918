import superagent from 'superagent';
import { sendMessage } from '../utils/sendBackgroundMessage';

export const PREFETCHED_ENDPOINT_URL = `${process.env.NEXT_PUBLIC_NLP_BASE}/extract_prefetched`;

const TIMEOUT = 10000;
const HTML_WEIGHT_LIMIT = 2 * 1024 * 1024; // 2MB

const stringWeight = (str: string) => new Blob([str]).size;

export const triggerPrefetchFallback = async (
  searchQueryId: string,
  url: string,
  searchQuery: string,
  fullTextSearchQuery: string | undefined,
  track: boolean,
) => {
  try {
    const html = await sendMessage<string>(
      {
        type: 'FETCH_PAGE',
        payload: {
          url,
        },
      },
      TIMEOUT,
    );

    if (stringWeight(html) > HTML_WEIGHT_LIMIT) {
      throw new Error('HTML is too big!');
    }

    await superagent
      .post(PREFETCHED_ENDPOINT_URL)
      .send({
        fullTextSearchQuery,
        query: searchQuery,
        searchQueryId,
        url,
        html,
        limit: 100,
        track,
      })
      .withCredentials();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Prefetch fallback failed', e);
  }
};
