import { last } from 'lodash';

import { MatchToken } from '@/ext/app/state/searchResultContent';
import { SearchResult } from '@/ext/app/state/types';
import { DatapointResult } from '@/components/pages/DatapointsPage/types';

export const WORD_NUMBERS = [
  /\Wzero\W/i,
  /\Wone\W/i,
  /\Wtwo\W/i,
  /\Wthree\W/i,
  /\Wfour\W/i,
  /\Wfive\W/i,
  /\Wsix\W/i,
  /\Wseven\W/i,
  /\Weight\W/i,
  /\Wnine\W/i,
  /\Wten\W/i,
  /\Weleven\W/i,
  /\Wtwelve\W/i,
  /\Wthirteen\W/i,
  /\Wfourteen\W/i,
  /\Wfifteen\W/i,
  /\Wsixteen\W/i,
  /\Wseventeen\W/i,
  /\Weighteen\W/i,
  /\Wnineteen\W/i,
  /\Wtwenty\W/i,
  /\Wthirty\W/i,
  /\Wforty\W/i,
  /\Wfifty\W/i,
  /\Wsixty\W/i,
  /\Wseventy\W/i,
  /\Weighty\W/i,
  /\Wninety\W/i,
  /\Whundred\W/i,
  /\Wthousand\W/i,
  /\Wmillion\W/i,
  /\Wbillion\W/i,
  /\Wtrillion\W/i,
  /\Wfirst\W/i,
  /\Wsecond\W/i,
  /\Wthird\W/i,
  /\Wfourth\W/i,
  /\Wfifth\W/i,
  /\Wsixth\W/i,
  /\Wseventh\W/i,
  /\Weighth\W/i,
  /\Wninth\W/i,
  /\Wtenth\W/i,
];

const ADDITIONAL_INCLUSIONS = [
  /\Wmore\W/i,
  /\Wless\W/i,
  /\Wfew\W/i,
  /\Wnumerous\W/i,
  /\Wsome\W/i,
  /\Wmany\W/i,
  /\Wmuch\W/i,
  /\Wseveral\W/i,
  /\Wmultiple\W/i,
  /\Wlittle\W/i,
  /\Wa lot\W/i,
  /\Wmost\W/i,
  /\Wleast\W/i,
  /\Wabundant\W/i,
  /\Wscarce\W/i,
  /\Wplenty\W/i,
  /\Wenough\W/i,
  /\Wa few\W/i,
  /\Wa handful\W/i,
  /\Wa ton\W/i,
  /\Wa great deal\W/i,
  /\Wminimal\W/i,
  /\Wexcessive\W/i,
  /\Wlimited\W/i,
  /\Wsubstantial\W/i,
  /\Wmoderate\W/i,
  /\Wlarge\W/i,
  /\Wsmall\W/i,
  /\Wconsiderable\W/i,
  /\Wsignificant\W/i,
  /\Wslight\W/i,
  /\Wtiny\W/i,
  /\Wvast\W/i,
  /\Wimmense\W/i,
  /\Wheavy\W/i,
  /\Wlight\W/i,
  /\Wgreater\W/i,
  /\Wlesser\W/i,
  /\Whigher\W/i,
  /\Wlower\W/i,
  /\Wincreased\W/i,
  /\Wdecreased\W/i,
  /\Wmajority\W/i,
  /\Wminority\W/i,
  /\Wpartial\W/i,
  /\Wtotal\W/i,
  /\Wfull\W/i,
  /\Wempty\W/i,
  /\Wcomplete\W/i,
  /\Wincomplete\W/i,
  ...WORD_NUMBERS,
];

/**
 * Remove characters that can conflict when comparing match values.
 */
export const cleanedMatch = (match: string): string =>
  match.replace('...', '').replace(/[’'\s]/g, '');

/**
 * Check if a set of `matchTokens` have more than just cardinal tokens.
 */
export const hasMoreThanCardinalTokens = (matchTokens: MatchToken[]): boolean =>
  matchTokens.some(([, type]) =>
    ['MONEY', 'QUANTITY', 'PERCENT'].includes(type),
  );

/**
 * Check if a set of `matchTokens` have more than just date tokens.
 */
export const hasMoreThanDateTokens = (matchTokens: MatchToken[]): boolean =>
  matchTokens.some(([, type]) => type !== 'DATE');

/**
 * Check if a string contains any numbers (digits or word numbers).
 */
export const includesNumbers = (text: string): boolean => {
  const digits = text?.match(/\d+/g) || [];
  const wordNumbers =
    text
      ?.match(/\w+/g)
      ?.filter((x) => WORD_NUMBERS.some((y) => y.test(` ${x} `))) || [];

  return digits.length > 0 || wordNumbers.length > 0;
};

/**
 * Check if a string contains any numbers that are not just dates (years).
 */
export const hasMoreThanDateNumbers = (text: string): boolean => {
  const numbers = text?.match(/\d+/g);
  const years = numbers?.filter(
    (x) => x.length === 4 && Number(x) > 1900 && Number(x) < 2100,
  );

  return (
    (!!numbers &&
      numbers.length > 0 &&
      (!years || numbers.length > years.length)) ||
    ADDITIONAL_INCLUSIONS.some((x) => x.test(text))
  );
};

/**
 * Get a text value from an HTML string.
 */
export const textFromHTML = (html: string): string =>
  new DOMParser().parseFromString(html, 'text/html').body.textContent || '';

/**
 * Find the datapoint for the given `searchResult` if it `hasMoreThanDateNumbers`.
 */
export const datapointForSearchResult = (
  datapoints: DatapointResult[],
  searchResult: SearchResult,
): DatapointResult | undefined => {
  const description =
    searchResult.description ||
    textFromHTML(searchResult.descriptionHTML || '');

  if (!description || !hasMoreThanDateNumbers(description)) {
    return;
  }

  const descriptionDatapoints = datapoints.filter((datapoint) => {
    const d = cleanedMatch(description);
    const f = cleanedMatch(datapoint.matchContextFull);
    const m = cleanedMatch(datapoint.match);

    return d.includes(m) || m.includes(d) || d.includes(f) || f.includes(d);
  });

  const { descriptionHTML } = searchResult;

  // Default to a temp `Match` so we can display something as soon as possible.
  const datapoint = last(descriptionDatapoints) || {
    distance: 0,
    match: description,
    matchMarkdown: descriptionHTML || '',
    matchStart: description,
    matchEnd: '',
    matchContextAfter: '',
    matchContextFull: '',
    matchContextFullMarkdown: '',
    matchContextAfterMarkdown: '',
    matchTokens: [],
  };

  return {
    ...datapoint,
    match: description || datapoint.match,
    matchMarkdown: descriptionHTML || datapoint.matchMarkdown,
    searchResult,
  };
};
