import { first, invert } from 'lodash/fp';
import { selector, useRecoilValue } from 'recoil';

import {
  ContentTypesKey,
  DEFAULT_CONTENT_TYPE,
} from '../contentTypes/constants';
import { waldoSearchQueryFiltersState } from './waldoQuery';

export const ContentTypeToFilterName: Record<ContentTypesKey, string> = {
  keywords: 'all',
  dataPoints: 'datapoints',
  dates: 'dates',
  emails: 'emails',
  quotations: 'quotes',
  phones: 'phones',
  linksWithMentions: 'links',
  socialLinks: 'social media',
};

const filterNameToContentType = invert(ContentTypeToFilterName) as Record<
  string,
  ContentTypesKey
>;

export const mapDisplayToContentType = (
  name?: string,
): ContentTypesKey | undefined =>
  name ? filterNameToContentType[name.toLowerCase()] : undefined;

export const ContentTypesKeys = Object.keys(
  ContentTypeToFilterName,
) as unknown as ContentTypesKey[];

export const selectedContentTypeRawState = selector({
  key: 'selectedContentTypeRawState',
  get: ({ get }) =>
    mapDisplayToContentType(first(get(waldoSearchQueryFiltersState).skim)),
});

export const selectedContentTypeState = selector({
  key: 'selectedContentTypeState',
  get: ({ get }) => get(selectedContentTypeRawState) || DEFAULT_CONTENT_TYPE,
});

export const expandedSearchResultState = selector({
  key: 'expandedSearchResultState',
  get: ({ get }) => !!get(selectedContentTypeRawState),
});

export const useSelectedContentTypeRaw = (): ContentTypesKey | undefined =>
  useRecoilValue(selectedContentTypeRawState);

export const useSelectedContentType = (): ContentTypesKey =>
  useRecoilValue(selectedContentTypeState);

export const useExpandedSearchResult = (): boolean =>
  useRecoilValue(expandedSearchResultState);
