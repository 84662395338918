import {
  atom,
  noWait,
  selector,
  SetterOrUpdater,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';

import { userAsyncState } from './user';

export type ValueType = boolean | 'dismissed';

const connectionIssuesState = atom<ValueType>({
  key: 'connectionIssues',
  default: false,
});

const combinedState = selector<ValueType>({
  key: 'userError',
  get: ({ get }) =>
    get(connectionIssuesState) ||
    get(noWait(userAsyncState)).state === 'hasError',
});

export const useConnectionIssues = (): ValueType =>
  useRecoilValue(combinedState);

export const useSetConnectionIssues = (): SetterOrUpdater<ValueType> =>
  useSetRecoilState(connectionIssuesState);
