export const fetchIframeWindow = async (
  html: string,
  href: string,
  className?: string,
): Promise<Window> =>
  new Promise((resolve, reject) => {
    const iframe = document.createElement('iframe');
    iframe.style.zIndex = '-1';
    iframe.style.background = 'black';
    iframe.style.inset = '0';
    iframe.style.position = 'fixed';
    iframe.style.height = '100%';
    iframe.style.width = '100%';
    iframe.style.marginLeft = '100vw';
    if (className) {
      iframe.classList.add(className);
    }

    document.body.appendChild(iframe);

    if (!iframe) {
      return;
    }

    iframe.onerror = (e) => {
      reject(e);

      iframe.onerror = null;
      iframe.onload = null;
    };

    iframe.addEventListener('load', () => {
      if (iframe.contentWindow) {
        resolve(iframe.contentWindow);
      } else {
        reject(new Error(`Failed to load src`));
      }

      iframe.onerror = null;
      iframe.onload = null;
    });

    iframe.srcdoc = html.replace('</head>', `<base href="${href}" /></head>`);
  });
