const DEFAULT_MIN_IMAGE_SIZE = 300;

/*
  This async method creates and loads an image in the document
  but doesn't append to any element in the body. After loading
  the image, it can decide if the image fulfills the minimum
  size specifications or not.
*/
export const checkMinimumImageSize = async (
  src: string,
  minWidth = DEFAULT_MIN_IMAGE_SIZE,
  minHeight = DEFAULT_MIN_IMAGE_SIZE,
): Promise<boolean> =>
  new Promise((resolve, reject) => {
    const img = document.createElement('img');

    img.src = src;

    img.onload = () => {
      const { naturalHeight: height = 0, naturalWidth: width = 0 } = img;
      resolve(height >= minHeight && width >= minWidth);
    };

    img.onerror = () => reject(Error(`Failed to load img with src ${src}`));
  });
