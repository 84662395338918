import { FunctionComponent } from 'react';

import { styled, SvgIcon, SvgIconProps } from '@mui/material';

import PinOutlinedIcon from './icon.svg';

const Icon = styled(PinOutlinedIcon)(() => ({
  '&.MuiSvgIcon-root': {
    width: 'auto',
    height: 'auto',
  },
}));

const PinOutlined: FunctionComponent<SvgIconProps> = (props) => (
  <SvgIcon
    component={Icon}
    width="17"
    height="16"
    viewBox="0 0 17 16"
    {...props}
  />
);

export default PinOutlined;
