import { Box, Button, styled, TextField, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { Controller } from 'react-hook-form';

import { ExploreFormInputProps } from './types';
import { useExploreFormInputProps } from './useExploreFormInputProps';

export const AUTOCOMPLETE_CHARACTER = '⇥';

export const EXPLORE_INPUT_ID = 'explore-input';

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  position: 'sticky',
  top: 0,
  zIndex: 1,
}));

const SearchField = styled(TextField)(({ theme }) => ({
  height: theme.spacing(2.5),
  width: '100%',
  margin: theme.spacing(1, 0),

  '.MuiInputBase-formControl': {
    color: 'text.high',
    height: theme.spacing(2.5),
    backgroundColor: theme.palette.background.paper,
    ...theme.typography.button,
  },
}));

const Form = styled('form')(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  position: 'relative',
}));

const AutoSuggest = styled(Box)(({ theme }) => ({
  left: theme.spacing(1),
  opacity: 0.5,
  pointerEvents: 'none',
  position: 'absolute',
  top: 'calc(100% / 2 - 7.5px)',
}));

const ExploreFormInput: FunctionComponent<ExploreFormInputProps> = (props) => {
  const {
    onKeyDown,
    control,
    value,
    autosuggest,
    isValid,
    onClear,
    onChange,
    onSubmit,
  } = useExploreFormInputProps(props);

  return (
    <Container>
      <Form onSubmit={onSubmit}>
        <Controller
          name="search"
          control={control}
          render={(field) => (
            <Box display="flex" position="relative">
              <SearchField
                {...field}
                data-component="ExploreFormInput"
                variant="outlined"
                color="primary"
                placeholder="Type to find a filter"
                onChange={onChange}
                onKeyDown={onKeyDown}
                value={value}
                InputProps={{
                  autoComplete: 'off',
                  id: EXPLORE_INPUT_ID,
                  endAdornment: (
                    <Box display="flex" gap={1}>
                      {value && (
                        <Button variant="text" onClick={onClear}>
                          <Typography variant="button" color="text.disabled">
                            Cancel
                          </Typography>
                        </Button>
                      )}
                      {isValid && (
                        <Button variant="text" onClick={onSubmit}>
                          <Typography variant="button" color="primary.main">
                            Apply
                          </Typography>
                        </Button>
                      )}
                    </Box>
                  ),
                }}
              />
              {autosuggest && (
                <AutoSuggest display="flex">
                  <Typography variant="button" color="text.disabled">
                    {`${autosuggest}${AUTOCOMPLETE_CHARACTER}`}
                  </Typography>
                </AutoSuggest>
              )}
            </Box>
          )}
        />
      </Form>
    </Container>
  );
};

export default ExploreFormInput;
