export type ContentTypesKey =
  | 'keywords'
  | 'dataPoints'
  | 'dates'
  | 'emails'
  | 'phones'
  | 'quotations'
  | 'linksWithMentions'
  | 'socialLinks';

export type ContentTypes = Record<
  ContentTypesKey,
  {
    label: string;
    hotkeys?: string[];
  }
>;

export const CONTENT_TYPES: ContentTypes = {
  keywords: {
    label: 'Skim All',
    hotkeys: ['A'],
  },
  dataPoints: {
    label: 'Datapoints',
    hotkeys: ['D'],
  },
  dates: {
    label: 'Dates',
    hotkeys: ['T'],
  },
  emails: {
    label: 'Emails',
    hotkeys: ['E'],
  },
  quotations: {
    label: 'Quotes',
    hotkeys: ['Q'],
  },
  phones: {
    label: 'Phones',
  },
  socialLinks: {
    label: 'Social Media',
  },
  linksWithMentions: {
    label: 'Links',
  },
};

export const DEFAULT_CONTENT_TYPE: ContentTypesKey = 'keywords';

export type Counts = {
  [key in ContentTypesKey]: number;
};
