import {
  atomFamily,
  SetterOrUpdater,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';

import { useSearchQueryId } from './searchResult';
import { Entity } from './types';

export type ValueType = Entity[];

export const topEntitiesState = atomFamily<ValueType, string>({
  key: 'topEntities',
  default: [],
});

export const useTopEntities = (): ValueType =>
  useRecoilValue(topEntitiesState(useSearchQueryId()));

export const useSetTopEntities = (): SetterOrUpdater<ValueType> =>
  useSetRecoilState(topEntitiesState(useSearchQueryId()));
