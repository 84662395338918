import { useMemo } from 'react';
import { keyBy, lowerCase, uniqBy } from 'lodash/fp';

import { useExploreQuery, useExploreQueryGroup } from './exploreQuery';

import { FilterGroup } from './types/filterGroup';
import { FilterOption } from './types/filterOption';

import {
  useCountryFilterGroup,
  useCountryFilterGroupWithOptions,
} from './filters/country';
import {
  DATE_GROUP_NAME,
  useDateFilterGroup,
  useDateFilterGroupWithOptions,
} from './filters/date';
import {
  useFileTypeFilterGroup,
  useFileTypeFilterGroupWithOptions,
} from './filters/fileType';
import {
  useLanguageFilterGroup,
  useLanguageFilterGroupWithOptions,
} from './filters/language';
import {
  useLensFilterGroup,
  useLensFilterGroupWithOptions,
} from './filters/lens';
import {
  SITE_GROUP_NAME,
  useSiteFilterGroup,
  useSiteFilterGroupWithOptions,
} from './filters/site';
import {
  useSkimFilterGroup,
  useSkimFilterGroupWithOptions,
} from './filters/skim';
import {
  useXrayFilterGroup,
  useXrayFilterGroupWithOptions,
  XRAY_GROUP_NAME,
} from './filters/xray';

export const useFilterGroups = (): Omit<FilterGroup, 'query'>[] => {
  const skim = useSkimFilterGroup();
  const xray = useXrayFilterGroup();
  const lens = useLensFilterGroup();
  const date = useDateFilterGroup();
  const site = useSiteFilterGroup();
  const fileType = useFileTypeFilterGroup();
  const country = useCountryFilterGroup();
  const language = useLanguageFilterGroup();

  return useMemo(
    () => [skim, xray, lens, date, site, fileType, country, language],
    [skim, xray, lens, date, site, fileType, country, language],
  );
};

export const useFilterGroupsWithOptions = (): FilterGroup[] => {
  const skim = useSkimFilterGroupWithOptions();
  const xray = useXrayFilterGroupWithOptions();
  const lens = useLensFilterGroupWithOptions();
  const date = useDateFilterGroupWithOptions();
  const site = useSiteFilterGroupWithOptions();
  const fileType = useFileTypeFilterGroupWithOptions();
  const country = useCountryFilterGroupWithOptions();
  const language = useLanguageFilterGroupWithOptions();

  return useMemo(
    () => [skim, xray, lens, date, site, fileType, country, language],
    [skim, xray, lens, date, site, fileType, country, language],
  );
};

export const useFilteredFilterGroups = (
  queryValue?: string,
): {
  group: FilterGroup;
  options: FilterOption[];
}[] => {
  const groups = useFilterGroupsWithOptions();
  const group = useExploreQueryGroup();
  const exploreQuery = useExploreQuery();

  const query = queryValue || exploreQuery;

  return useMemo(
    () =>
      groups
        .filter((g) => !group || g.id === group)
        .map((g) => ({
          group: g,
          options: uniqBy(
            'value',
            (g.query.data?.pages.flat() ?? [])
              .filter(
                (option) =>
                  !query ||
                  option.label
                    .split(' ')
                    .some((val) =>
                      val.toLowerCase().startsWith(query.toLowerCase()),
                    ) ||
                  lowerCase(option.label).startsWith(query.toLowerCase()) ||
                  g.id.startsWith(query),
              )
              .concat(
                query &&
                  (g.id === XRAY_GROUP_NAME ||
                    g.id === SITE_GROUP_NAME ||
                    g.id === DATE_GROUP_NAME)
                  ? [{ label: query, value: query, groupName: g.id }]
                  : [],
              ),
          ),
        }))
        .filter((g) => g.options.length > 0),
    [groups, group, query],
  );
};

export const useKeyedFilterGroups = (): Record<
  string,
  Omit<FilterGroup, 'query'>
> => {
  const groups = useFilterGroups();
  return useMemo(() => keyBy('name', groups), [groups]);
};
