import {
  atom,
  SetterOrUpdater,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';

export type ValueType = string[] | undefined;

export const selectedSourcesState = atom<ValueType>({
  key: 'selectedSourcesState',
  default: undefined,
});

export const useSelectedSources = (): ValueType =>
  useRecoilValue(selectedSourcesState);

export const useSetSelectedSources = (): SetterOrUpdater<ValueType> =>
  useSetRecoilState(selectedSourcesState);

export const useSetSelectedSourcesState = (): [
  ValueType,
  SetterOrUpdater<ValueType>,
] => useRecoilState(selectedSourcesState);
