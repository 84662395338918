import { FunctionComponent } from 'react';

import { styled, SvgIcon, SvgIconProps } from '@mui/material';

import HashTagIcon from './icon.svg';

const Icon = styled(HashTagIcon)(() => ({
  '&.MuiSvgIcon-root': {
    width: 'auto',
    height: 'auto',
  },
}));

const HashTag: FunctionComponent<SvgIconProps> = (props) => (
  <SvgIcon component={Icon} height="16" viewBox="0 0 16 16" {...props} />
);

export default HashTag;
