import { TrackableEvent, TrackableTarget } from '@/ext/lib/trackable';
import { FilterOptionId } from '../state/search/types/filterOption';
import { useTracking } from '../state/tracking';

export const useTrackFilters = (): ((filters: FilterOptionId[]) => void) => {
  const trackEvent = useTracking();

  return (filters: FilterOptionId[]) => {
    for (const filter of filters) {
      switch (filter.groupName) {
        case 'xray': {
          trackEvent(TrackableEvent.ACTION, {
            target: TrackableTarget.XRAY_SEARCH,
          });
          break;
        }
        case 'lens': {
          trackEvent(TrackableEvent.ACTION, {
            target: TrackableTarget.LENS_FILTER,
          });
          break;
        }
        case 'date': {
          trackEvent(TrackableEvent.ACTION, {
            target: TrackableTarget.DATES_SELECTED,
          });
          break;
        }
        case 'site': {
          trackEvent(TrackableEvent.ACTION, {
            target: TrackableTarget.SITE_FILTER,
          });
          break;
        }
        case 'filetype': {
          trackEvent(TrackableEvent.ACTION, {
            target: TrackableTarget.FILETYPE_FILTER,
          });
          break;
        }
        case 'country': {
          trackEvent(TrackableEvent.ACTION, {
            target: TrackableTarget.COUNTRY_FILTER,
          });
          break;
        }
        case 'language': {
          trackEvent(TrackableEvent.ACTION, {
            target: TrackableTarget.LANGUAGE_FILTER,
          });
          break;
        }
        case 'skim': {
          switch (filter.value) {
            case 'all': {
              trackEvent(TrackableEvent.ACTION, {
                target: TrackableTarget.KEYWORDS_SELECTED,
              });
              break;
            }
            case 'datapoints': {
              trackEvent(TrackableEvent.ACTION, {
                target: TrackableTarget.DATAPOINTS_SELECTED,
              });
              break;
            }
            case 'dates': {
              trackEvent(TrackableEvent.ACTION, {
                target: TrackableTarget.DATES_SELECTED,
              });
              break;
            }
            case 'emails': {
              trackEvent(TrackableEvent.ACTION, {
                target: TrackableTarget.EMAILS_SELECTED,
              });
              break;
            }
            case 'phones': {
              trackEvent(TrackableEvent.ACTION, {
                target: TrackableTarget.PHONES_SELECTED,
              });
              break;
            }
            case 'social media': {
              trackEvent(TrackableEvent.ACTION, {
                target: TrackableTarget.SOCIAL_LINKS_SELECTED,
              });
              break;
            }
            case 'quotes': {
              trackEvent(TrackableEvent.ACTION, {
                target: TrackableTarget.QUOTATIONS_SELECTED,
              });
              break;
            }
            case 'links': {
              trackEvent(TrackableEvent.ACTION, {
                target: TrackableTarget.LINKS_SELECTED,
              });
              break;
            }
            default: {
              break;
            }
          }
        }
        default: {
          break;
        }
      }
    }
  };
};
