import { take } from 'lodash';
import superagent from 'superagent';

import { SelectListOption } from '../ui/SelectList';

const ENDPOINT_URL = `${process.env.NEXT_PUBLIC_API_BASE}/v1/top_sites`;

const MOST_POPULAR_TLD = [
  'gov',
  'edu',
  'net',
  'org',
  'jp',
  'de',
  'uk',
  'fr',
  'br',
  'it',
  'ru',
  'es',
  'me',
  'pl',
  'ca',
  'au',
  'cn',
  'co',
  'in',
  'nl',
  'info',
  'eu',
  'ch',
  'id',
  'at',
  'kr',
  'cz',
  'mx',
  'be',
  'tv',
  'se',
  'tr',
  'tw',
  'al',
  'ua',
  'ir',
  'vn',
  'cl',
  'sk',
  'ly',
  'cc',
  'to',
  'no',
  'fi',
  'us',
  'pt',
  'dk',
  'ar',
  'hu',
  'tk',
  'gr',
  'il',
  'news',
  'ro',
  'my',
  'biz',
  'ie',
  'za',
  'nz',
  'sg',
  'ee',
  'th',
  'io',
  'xyz',
  'pe',
  'bg',
  'hk',
  'rs',
  'lt',
  'link',
  'ph',
  'club',
  'si',
  'site',
  'mobi',
  'by',
  'cat',
  'wiki',
  'la',
  'ga',
  'cf',
  'hr',
  'ng',
  'jobs',
  'online',
  'kz',
  'ug',
  'gq',
  'ae',
  'is',
  'lv',
  'pro',
  'fm',
  'tips',
  'ms',
  'sa',
  'app',
];

export const getTopDomains = async (
  query?: string,
  limit = 100,
): Promise<SelectListOption[]> => {
  let topDomains: SelectListOption[] = [];
  const req = await superagent.get(ENDPOINT_URL).query({
    q: query,
    limit,
  });

  topDomains = req.body.map((value: string) => ({
    label: value,
    value,
  }));

  let TLDs: SelectListOption[] = [];
  // Only prepend TLD wildcards if
  // - No domain search (PowerSearch just opened)
  // - Domain search is . or *.
  if (!query || query.startsWith('.') || query.startsWith('*.')) {
    TLDs = take(
      MOST_POPULAR_TLD.filter((tld) =>
        // - If there's no domain search we show the whole list
        // - If there's domain search we filter the list
        !query ? true : `.${tld}`.startsWith(query.replace('*', '')),
      ).map((tld) => {
        // - If domain search includes '*' char, we show the
        //   TLDs wildcards with it, i.e.: site:*.edu
        // - If not included, we just show the ., i.e.: site:.edu
        const label = `${query?.startsWith('*.') ? '*' : ''}.${tld}`;
        return {
          label,
          value: label,
        };
      }),
      // No domain search we only prepend the first 2 TLD wildcards
      // (we show some domain options as well)
      !query ? 2 : 20,
    );
  }

  return [...TLDs, ...topDomains];
};
