import {
  concat,
  flatten,
  flow,
  groupBy,
  isEmpty,
  join,
  map,
  mapValues,
  reject,
  split,
} from 'lodash/fp';
import {
  atom,
  selector,
  useRecoilCallback,
  useRecoilValue,
  waitForAll,
} from 'recoil';

import { useCallback } from 'react';
import { sessionStorageEffect } from '../utils/recoil';
import { defaultSearchEngineState } from './engine';
import { lensState } from './lenses';
import { selectedSearchResultState } from './selectedSearchResult';
import {
  selectedFiltersState,
  useSelectedFiltersState,
} from '@/ext/app/state/search/selectedFilterOptions';
import { prepend } from '@/ext/lib/string';
import { getDateRange } from '../utils/getDateRange';
import { getInitialQuery } from '../core/getInititialQuery';
import { SearchQuery } from './types';
import { allowTypoState } from './allowTypo';

/**
 * Number of pages state
 *
 * - Reset the num pages state when the search query changes.
 */
export const numPagesState = atom<number>({
  key: 'numPagesState',
  default: 1,
  effects: [sessionStorageEffect],
});

/**
 * Waldo Query state
 */
type ValueType = string;

export const waldoSearchQueryState = atom<string>({
  key: 'waldoSearchQueryState',
  default: typeof window === 'undefined' ? '' : getInitialQuery(),
});

/**
 * The textual representation of the search query
 */
export const searchQueryTextState = selector({
  key: 'searchQueryTextState',
  get: ({ get }) => get(waldoSearchQueryState),
});

export const useSearchQueryText = (): string =>
  useRecoilValue(searchQueryTextState);

/**
 * Query Filters
 *
 * - xray, skim, date, etc.
 */
export const waldoSearchQueryFiltersState = atom<Record<string, string[]>>({
  key: 'waldoSearchQueryFiltersState',
  default: selector({
    key: 'defaultWaldoSearchQueryFiltersState',
    get: ({ get }) => {
      const filters = get(selectedFiltersState);
      const result = flow(
        () => filters,
        groupBy('groupName'),
        mapValues(map('value')),
      )();
      return result;
    },
  }),
});

export const useWaldoSearchQueryFilters = (): Record<string, string[]> =>
  useRecoilValue(waldoSearchQueryFiltersState);

/**
 * Sites Filter
 */
export const searchQuerySitesClause = selector({
  key: 'searchQuerySitesClause',
  get: ({ get }) => {
    const filters = get(waldoSearchQueryFiltersState);
    const sites = filters.site ?? [];
    const lensIds = map((x) => parseInt(x, 10), filters.lens ?? []);
    const lenses = get(waitForAll(map(lensState, lensIds)));

    return flow(
      () => lenses,
      map('resources'),
      flatten,
      map('url'),
      concat(sites),
      map(prepend('site:')),
      join(' OR '),
      (query) => (query ? `(${query})` : ''),
    )();
  },
});

/**
 * Filetypes clause
 */
export const searchQueryFiletypesClause = selector({
  key: 'searchQueryFiletypesClause',
  get: ({ get }) => {
    const filters = get(waldoSearchQueryFiltersState);
    const filetypes = filters.filetype ?? [];
    return flow(
      () => filetypes,
      map(split(',')),
      flatten,
      map(prepend('filetype:')),
      (types) => {
        if (types.length) {
          return types.length > 1 ? `(${types.join(' OR ')})` : types[0];
        }
        return null;
      },
    )();
  },
});

/**
 * Full text search query state
 */
export const fullTextSearchQueryState = selector({
  key: 'fullTextSearchQueryState',
  get: ({ get }) =>
    get(waldoSearchQueryFiltersState).xray?.join(' ') || undefined,
});

/**
 * Search Query state
 *
 * - Query passed to Google, Bing
 */
export const searchQueryState = selector<SearchQuery>({
  key: 'derivedSearchQueryDefaultState',
  get({ get }) {
    const allowTypo = get(allowTypoState) ? '1' : undefined;
    const engine = get(defaultSearchEngineState);
    const query = get(waldoSearchQueryState);
    const filters = get(waldoSearchQueryFiltersState);
    const country = filters.country?.[0];
    const language = filters.language?.[0];
    const dateRange = getDateRange(filters.date?.[0]);

    const clauses = [
      query,
      get(searchQuerySitesClause),
      get(searchQueryFiletypesClause),
    ];
    const searchQuery = flow(reject(isEmpty), join(' AND '))(clauses);

    return {
      allowTypo,
      engine,
      searchQuery,
      country,
      language,
      ...dateRange,
    };
  },
});

export const useSearchQuery = (): string =>
  useRecoilValue(searchQueryState).searchQuery;

/**
 * Selected Lenses Titles state
 */
export const selectedLensesTitlesSelector = selector<string[]>({
  key: 'selectedLensesTitles',
  get: ({ get }) => {
    const { lens } = get(waldoSearchQueryFiltersState);

    return lens ? lens.filter((l) => !!l) : [];
  },
});

export const useSelectedLensesTitles = (): string[] =>
  useRecoilValue(selectedLensesTitlesSelector);

export const useWaldoSearchQuery = (): ValueType =>
  useRecoilValue(waldoSearchQueryState);

export const useSetWaldoSearchQuery = (): ((value: string) => Promise<void>) =>
  useRecoilCallback(
    ({ set, reset }) =>
      async (waldoQuery) => {
        set(numPagesState, () => 1);
        reset(selectedSearchResultState);
        set(waldoSearchQueryState, () => waldoQuery);
      },
    [],
  );

export const useClearFilters = (): (() => void) => {
  const [selectedFilters, setSelectedFilters] = useSelectedFiltersState();

  return useCallback(() => {
    if (selectedFilters.length !== 0) {
      setSelectedFilters([]);
    }
  }, [selectedFilters, setSelectedFilters]);
};
