import { isEqual, mapValues } from 'lodash';
import { useRouter } from 'next/router';
import {
  atom,
  atomFamily,
  selector,
  selectorFamily,
  useRecoilCallback,
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil';
import superagent from 'superagent';
import { v5 as uuid, v4 } from 'uuid';
import { useMutation, useQuery } from 'react-query';
import { sortBy, uniqBy } from 'lodash/fp';

import { resultsState, useSearchQueryId } from '@/ext/app/state/searchResult';
import {
  SearchResultContentKey,
  searchResultContentSelector,
  StaticContentTypes,
} from '@/ext/app/state/searchResultContent';
import {
  searchResultSelectedSelector,
  selectedSearchResultState,
} from '@/ext/app/state/selectedSearchResult';
import { SearchResult } from '@/ext/app/state/types';
import { datapointForSearchResult, hasMoreThanCardinalTokens } from './utils';
import {
  DatapointGroup,
  DatapointResult,
} from '@/components/pages/DatapointsPage/types';
import { fullTextSearchQueryState } from '@/ext/app/state/fullTextSearchQuery';
import { isFactCheckMatch } from '@/components/pages/DatapointsPage/utils';
import { waldoSearchQueryState } from '@/ext/app/state/waldoQuery';
import { IdeateAPI } from '@/rpc/ideate';
import { IdeateProject } from '@/ext/types';
import { queryClient } from '@/components/providers/Dependencies';
import { searchSummaryTransformedState } from './summary';
import { DAY_MS } from '@/constants';
import { FilterOptionId } from '@/ext/app/state/search/types/filterOption';

const UUID_NAMESPACE = v4();

const ideateApi = new IdeateAPI();

export const selectedIdeateProjectState = atom<IdeateProject | null>({
  key: 'selectedIdeateProjectState',
  default: null,
});

export const useSelectedIdeateProject = () =>
  useRecoilValue(selectedIdeateProjectState);
export const useSetSelectedIdeateProject = () =>
  useSetRecoilState(selectedIdeateProjectState);

/**
 * atomFamily for storing the grouped datapoints for a search result.
 * */
export const datapointGroupState = atomFamily<
  DatapointGroup | undefined,
  string
>({
  key: 'datapointGroupState',
  default: undefined,
});

/**
 * atomFamily for storing the conflicting datapoints for a search result.
 * */
export const datapointConflictingState = atomFamily<
  DatapointGroup | undefined,
  string
>({
  key: 'datapointConflictingState',
  default: undefined,
});

/**
 * Selector that returns all datapoints that have the follow criteria:
 * - a distance greater than 0.75
 * - have more than just cardinal tokens OR are in the list of cardinals
 */
export const datapointResults = selector<DatapointResult[]>({
  key: 'datapointResults',
  get: ({ get }) => {
    const g = (
      url: string,
      contentType: SearchResultContentKey['contentType'] = 'dataPoints',
    ) =>
      get(
        searchResultContentSelector({
          props: { contentType },
          url,
        }),
      )?.data;

    const formatDatapointGroups = (datapointGroups: {
      [url: string]: number[];
    }) =>
      mapValues(datapointGroups, (idxs: number[], url) => {
        const datapoints = g(url)?.dataPoints;

        return idxs.map((idx) => datapoints?.[idx]!);
      });

    const xray = get(fullTextSearchQueryState);
    const results = get(resultsState)
      .flatMap((searchResult) => {
        const cardinals =
          g(searchResult.url, StaticContentTypes.CARDINALS)?.cardinals || [];

        const type = xray ? 'keywords' : 'dataPoints';
        const contents = g(searchResult.url, type);

        if (xray) {
          return contents?.[type].map((value) => ({
            ...value,
            searchResult,
          }));
        }

        const result = contents?.dataPoints
          ?.map((value, index) => ({
            value,
            index,
          }))
          .filter(
            ({ value: { matchTokens, distance } }, i) =>
              xray ||
              (distance >= 0.75 &&
                (hasMoreThanCardinalTokens(matchTokens) ||
                  cardinals.includes(i))),
          )
          ?.map<DatapointResult>(({ value, index }) => {
            const duplicates = get(
              datapointGroupState(`${searchResult.url}:${index}`),
            );
            const conflicts = get(
              datapointConflictingState(`${searchResult.url}:${index}`),
            );

            return {
              ...value,
              conflicts: conflicts && formatDatapointGroups(conflicts),
              duplicates: duplicates && formatDatapointGroups(duplicates),
              searchResult,
            };
          });

        return result;
      })
      .filter((d): d is DatapointResult => !!d);

    return results;
  },
});

/**
 * Selector that returns a result count for each domain / source.
 */
export const datapointSourceCount = selectorFamily<number, string>({
  key: 'datapointSourceCount',
  get:
    (source) =>
    ({ get }) => {
      const results = get(resultsState);

      return results.filter(({ domain }) => domain === source).length;
    },
});

export const useDatapointSourceCount = (source: string) =>
  useRecoilValue(datapointSourceCount(source));

/**
 * A selector to fetch the index of a search result.
 */
export const datapointSourceIndex = selectorFamily<
  number,
  Readonly<SearchResult>
>({
  key: 'datapointSourceIndex',
  get:
    (searchResult) =>
    ({ get }) =>
      get(resultsState).findIndex((r) => isEqual(r, searchResult)),
});

export const useDatapointSourceIndex = (searchResult: SearchResult) =>
  useRecoilValue(datapointSourceIndex(searchResult));

/**
 * A selector to fetch "fact check" matches.
 */
export const factCheckDatapointResults = selector<DatapointResult[]>({
  key: 'factCheckDatapointResults',
  get: ({ get }) => {
    const query = get(waldoSearchQueryState);
    const results = get(datapointResults)
      .filter((result) => result.distance > 0.9)
      .sort((a, b) => (a.distance > b.distance ? -1 : 1))
      .map((result) => ({
        ...result,
        isMatch: isFactCheckMatch(query, result),
      }));

    return uniqBy((result) => result.searchResult.url, results);
  },
});

/**
 * A selector to fetch the "sorted" datapoints.
 *
 * First get all search results that have a datapoint in their `description`.
 * Then include all datapoints sorted by `distance`.
 */
export const sortedDatapointResults = selector<DatapointResult[]>({
  key: 'sortedDatapointResults',
  get: ({ get }) => {
    const xray = !!get(fullTextSearchQueryState);
    const datapoints = get(datapointResults);
    const searchResultDatapoints = get(resultsState)
      .map((searchResult) => {
        const datapoint = datapointForSearchResult(datapoints, searchResult);

        if (!datapoint) {
          return;
        }

        return {
          ...datapoint,
          searchResult,
        };
      })
      .filter((d): d is DatapointResult => !!d);

    const filtered = [...datapoints]
      .sort((a, b) => (a.distance > b.distance ? -1 : 1))
      .filter(
        (datapoint) =>
          // Compare only the `matchContextFull` to filter out since `datapointForDescription` can return a modified datapoint.
          !searchResultDatapoints.some((d) =>
            isEqual(datapoint.matchContextFull, d?.matchContextFull),
          ),
      );

    return xray
      ? sortBy('distance', datapoints)
      : [...searchResultDatapoints, ...filtered];
  },
});

/**
 * Main selector for fetching results by list type.
 */
export const datapointResultList = selectorFamily<DatapointResult[], boolean>({
  key: 'datapointResultList',
  get:
    (factCheck: boolean) =>
    ({ get }) => {
      const results = factCheck
        ? get(factCheckDatapointResults)
        : get(sortedDatapointResults);
      const selectedSearchResult = get(selectedSearchResultState);

      return results.filter(
        ({ searchResult }) =>
          !selectedSearchResult ||
          get(searchResultSelectedSelector(searchResult)),
      );
    },
});

export const useDatapointResultList = (factCheck: boolean) =>
  useRecoilValue(datapointResultList(factCheck));

export const ideateQueryRawState = atom<Record<number, string> | undefined>({
  key: 'ideateQueryRawState',
  default: undefined,
});

export const ideateQueriesRawState = atom<Record<number, string>>({
  key: 'ideateQueriesRawState',
  default: {},
});

export const updateSectionQuery = (
  project: IdeateProject,
  sectionIndex: number,
  queryIndex: number,
  query: any,
) => {
  const sections = project.data?.sections || [];

  return {
    data: {
      ...project.data,
      sections: [
        ...sections.slice(0, sectionIndex),
        {
          ...sections[sectionIndex],
          queries: [
            ...sections[sectionIndex]?.queries.slice(0, queryIndex),
            {
              ...sections[sectionIndex]?.queries[queryIndex],
              ...query,
            },
            ...sections[sectionIndex]?.queries.slice(queryIndex + 1),
          ],
        },
        ...sections.slice(sectionIndex + 1),
      ],
    },
  };
};

export const updateSection = (
  project: IdeateProject,
  sectionIndex: number,
  section: any,
) => {
  const sections = project.data?.sections || [];

  return {
    data: {
      ...project.data,
      sections: [
        ...sections.slice(0, sectionIndex),
        {
          ...sections[sectionIndex],
          ...section,
        },
        ...sections.slice(sectionIndex + 1),
      ],
    },
  };
};

export const addQueryToSection = (
  project: IdeateProject,
  sectionIndex: number,
  query: string,
) => {
  const sections = project.data?.sections || [];

  return {
    data: {
      ...project.data,
      sections: [
        ...sections.slice(0, sectionIndex),
        {
          ...sections[sectionIndex],
          queries: [
            ...sections[sectionIndex].queries,
            {
              query,
              searchResults: [],
              summary: null,
              id: v4(),
            },
          ],
        },
        ...sections.slice(sectionIndex + 1),
      ],
    },
  };
};

export const removeQueryFromSection = (
  project: IdeateProject,
  sectionIndex: number,
  queryIndex: number,
) => {
  const sections = project.data?.sections || [];

  return {
    data: {
      ...project.data,
      sections: [
        ...sections.slice(0, sectionIndex),
        {
          ...sections[sectionIndex],
          queries: [
            ...sections[sectionIndex]?.queries?.slice(0, queryIndex),
            ...sections[sectionIndex]?.queries?.slice(queryIndex + 1),
          ],
        },
        ...sections.slice(sectionIndex + 1),
      ],
    },
  };
};

export const removeSection = (project: IdeateProject, sectionIndex: number) => {
  const sections = project.data?.sections || [];

  return {
    data: {
      ...project.data,
      sections: [
        ...sections.slice(0, sectionIndex),
        ...sections.slice(sectionIndex + 1),
      ],
    },
  };
};

export const updateSectionTitle = (
  project: IdeateProject,
  sectionIndex: number,
  title: string,
) => {
  const sections = project.data?.sections || [];

  return {
    data: {
      ...project.data,
      sections: [
        ...sections.slice(0, sectionIndex),
        {
          ...sections[sectionIndex],
          title,
        },
        ...sections.slice(sectionIndex + 1),
      ],
    },
  };
};

export const topicQueriesRawState = atomFamily<Record<number, string>, string>({
  key: 'ideateQueryRawState',
  default: {},
});

export const topicQueriesState = atomFamily<
  {
    id: string;
    query: string;
    summary: { content: string } | null;
    searchResults: SearchResult[];
  }[],
  string
>({
  key: 'topicQueriesState',
  default: selectorFamily({
    key: 'defaultTopicQueriesState',
    get:
      (topicId: string) =>
      ({ get }) => {
        const rawState = get(topicQueriesRawState(topicId));

        const text = Object.values(rawState).join('');

        const result = text
          .split('\n')
          .filter((line) => !!line && /^[0-9]+\..*/.test(line))
          .map((line, index) => ({
            id: uuid(index.toString() + topicId, UUID_NAMESPACE),
            query: line
              .replace(/[0-9]*\./, '')
              .replaceAll('"', '')
              .trim(),
            summary: null,
            searchResults: [],
          }))
          .filter(({ query }) => !!query);

        return result;
      },
  }),
});

export const ideateReadyState = atom<Record<string, boolean>>({
  key: 'ideateReadyState',
  default: {},
});

export const ideateReadySelector = selector({
  key: 'ideateReadySelector',
  get: ({ get }) => {
    const readyState = get(ideateReadyState);

    return (
      Object.values(readyState).length > 0 &&
      Object.values(readyState).every((ready) => ready)
    );
  },
});

const ideateQueryState = atom({
  key: 'ideateQueryState',
  default: selector<{ id: string; title: string; queries: string[] }[]>({
    key: 'defaultIdeateQueryState',
    get: ({ get }) => {
      const rawState = get(ideateQueryRawState);

      if (!rawState) {
        return [];
      }

      const text = Object.values(rawState).join('');

      return text
        .split('\n')
        .filter((line) => line.includes('. '))
        .map((line, index) => ({
          id: uuid(index.toString(), UUID_NAMESPACE),
          title: line.replace(/[0-9]*\./, '').trim(),
          queries: [],
        }))
        .filter((line) => !!line.title);
    },
  }),
});

export const ideateQueryReadyState = atom({
  key: 'ideateQueryReadyState',
  default: false,
});

export const ideateQueryErrorState = atom({
  key: 'ideateQueryErrorState',
  default: false,
});

export const summaryReadyState = atomFamily({
  key: 'summaryReadyState',
  default: false,
});

export const summaryErrorState = atomFamily({
  key: 'summaryErrorState',
  default: false,
});

export const useIdeateTopics = () => useRecoilValue(ideateQueryState);
export const useIdeateTopicsLoadable = () =>
  useRecoilValueLoadable(ideateQueryState);
export const useSetIdeateTopics = () => useSetRecoilState(ideateQueryState);

const ideateQueriesReadyState = atom({
  key: 'ideateQueriesReadyState',
  default: false,
});

export const useIdeateQueriesReady = () =>
  useRecoilValue(ideateQueriesReadyState);
export const useSetIdeateQueriesReady = () =>
  useSetRecoilState(ideateQueriesReadyState);

export const useIdeateQueryReady = () => useRecoilValue(ideateQueryReadyState);
export const useSetIdeateQueryReady = () =>
  useSetRecoilState(ideateQueryReadyState);

export const streamingIdeateState = atom({
  key: 'streamingIdeateState',
  default: false,
});

export const useIdeateQueryError = () => useRecoilValue(ideateQueryErrorState);
export const useSetIdeateQueryError = () =>
  useSetRecoilState(ideateQueryErrorState);

export const useSummaryReady = (sqid: string) =>
  useRecoilValue(summaryReadyState(sqid));
export const useSetSummaryReady = (sqid: string) =>
  useSetRecoilState(summaryReadyState(sqid));

export const useSummaryError = (sqid: string) =>
  useRecoilValue(summaryErrorState(sqid));
export const useSetSummaryError = (sqid: string) =>
  useSetRecoilState(summaryErrorState(sqid));

export const sortedIdeateProjects = (projects: IdeateProject[]) =>
  projects.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  );

export const useDeleteIdeateProject = () => {
  const router = useRouter();
  const selectedProject = useSelectedIdeateProject();

  return useMutation<void, unknown, number>(
    (projectId: number) => ideateApi.deleteIdeateProject(projectId),
    {
      onMutate: async (projectId) => {
        const projects = queryClient.getQueryData(
          'ideateProjects',
        ) as IdeateProject[];

        if (!projects) {
          return;
        }

        const index = projects.findIndex((p) => p.projectId === projectId);
        const update = [
          ...(queryClient.getQueryData('ideateProjects') as IdeateProject[]),
        ];

        update.splice(index, 1);
        queryClient.setQueryData('ideateProjects', update);

        if (update.length === 0) {
          window.location.replace('/app/search');
        } else if (selectedProject?.projectId === projectId) {
          router.replace({
            pathname: '/ideate',
            query: { projectId: update[0].projectId },
          });
        }
      },
    },
  );
};

export const useGetIdeateProject = (
  projectId?: number,
  useErrorBoundary: boolean = false,
) =>
  useQuery(
    ['ideateProject', projectId],
    () => ideateApi.getIdeateProject(projectId!),
    {
      enabled: !!projectId,
      useErrorBoundary,
    },
  );

export const useGetAllIdeateProjects = () =>
  useQuery(
    'ideateProjects',
    async () => sortedIdeateProjects(await ideateApi.getAllIdeateProjects()),
    {
      cacheTime: DAY_MS,
    },
  );

export const useUpdateIdeateProject = (
  updateSelectedProject: boolean = false,
) => {
  const setIdeateProject = useSetSelectedIdeateProject();

  return useMutation(
    ({
      ideateProject,
      projectId,
    }: {
      projectId: number;
      ideateProject: { title?: string; data?: any };
    }) => ideateApi.updateIdeateProject(projectId, ideateProject),
    {
      onSuccess: (project) => {
        const projects = queryClient.getQueryData(
          'ideateProjects',
        ) as IdeateProject[];

        if (projects) {
          queryClient.setQueryData(
            'ideateProjects',
            projects.map((p) =>
              project.projectId === p.projectId ? project : p,
            ),
          );
        }

        if (updateSelectedProject) {
          setIdeateProject(project);
        }
      },
    },
  );
};

export const useUpdateSelectedIdeateProject = () =>
  useRecoilCallback(({ set, snapshot }) => async () => {
    const ideateProject = snapshot
      .getLoadable(selectedIdeateProjectState)
      .getValue();
    const sections = snapshot.getLoadable(ideateQueryState).getValue();

    if (ideateProject && !ideateProject.data?.sections?.length) {
      set(selectedIdeateProjectState, {
        ...ideateProject,
        data: {
          ...ideateProject.data,
          sections,
        },
      });
    }
  });

export const useUpdateSelectedIdeateProjectSectionQuery = () =>
  useRecoilCallback(({ snapshot, set }) => async (sectionIndex: number) => {
    const ideateProject = snapshot
      .getLoadable(selectedIdeateProjectState)
      .getValue();

    const section = ideateProject?.data?.sections?.[sectionIndex];

    if (ideateProject && section) {
      const queries = snapshot
        .getLoadable(topicQueriesState(section.id))
        .getValue();

      set(selectedIdeateProjectState, {
        ...ideateProject,
        ...updateSection(ideateProject, sectionIndex, { queries }),
      });
    }
  });

export const useUpdateSelectedIdeateProjectQuery = () => {
  const { mutateAsync: updateIdeateProject } = useUpdateIdeateProject(true);

  return useRecoilCallback(
    ({ snapshot }) =>
      async (
        searchQueryId: string,
        queryIndex: number,
        sectionIndex: number,
        query?: {
          searchResults: SearchResult[];
          filters: FilterOptionId[];
        },
      ) => {
        const ideateProject = snapshot
          .getLoadable(selectedIdeateProjectState)
          .getValue();
        const summary = snapshot
          .getLoadable(searchSummaryTransformedState(searchQueryId))
          .getValue();

        if (ideateProject) {
          await updateIdeateProject({
            projectId: ideateProject.projectId,
            ideateProject: updateSectionQuery(
              ideateProject,
              sectionIndex,
              queryIndex,
              { ...query, summary },
            ),
          });
        }
      },
  );
};

export const useUpdateSelectedIdeateProjectSearchResults = () => {
  const { mutateAsync: updateIdeateProject } = useUpdateIdeateProject();

  return useRecoilCallback(
    ({ snapshot, set }) =>
      async (
        searchResults: SearchResult[],
        sectionIndex: number,
        queryIndex: number,
      ) => {
        const ideateProject = snapshot
          .getLoadable(selectedIdeateProjectState)
          .getValue();

        if (ideateProject) {
          const updatedProject = await updateIdeateProject({
            projectId: ideateProject.projectId,
            ideateProject: updateSectionQuery(
              ideateProject,
              sectionIndex,
              queryIndex,
              { searchResults },
            ),
          });

          set(selectedIdeateProjectState, updatedProject);
        }
      },
  );
};

export const useFetchIdeateTopics = () => {
  const previousResponses = useIdeateTopics();
  const setIdeateQueryRawState = useSetRecoilState(ideateQueryRawState);
  const sqid = useSearchQueryId();

  return useMutation(
    async ({ project, teamId }: { project: string; teamId?: number }) =>
      superagent
        .post(`${process.env.NEXT_PUBLIC_NLP_BASE}/ideate`)
        .send({
          project,
          teamId,
          sqid,
          previousResponses,
        })
        .withCredentials(),
    {
      onMutate: () => {
        setIdeateQueryRawState([]);
      },
    },
  );
};

export const useFetchOutline = () => {
  const previousResponses = useIdeateTopics();
  const setIdeateQueryRawState = useSetRecoilState(ideateQueryRawState);
  const sqid = useSearchQueryId();

  return useMutation(
    async ({
      project,
      projectId,
      teamId,
    }: {
      project: string;
      projectId: number;
      teamId?: number;
    }) =>
      superagent
        .post(`${process.env.NEXT_PUBLIC_NLP_BASE}/outline`)
        .send({
          project,
          projectId,
          teamId,
          sqid,
          previousResponses,
        })
        .withCredentials(),
    {
      onMutate: () => {
        setIdeateQueryRawState([]);
      },
    },
  );
};

export const expandedTopicsState = atom<Record<string, boolean>>({
  key: 'expandedTopics',
  default: {},
});

export const useIsTopicExpanded = (id: string) =>
  useRecoilValue(expandedTopicsState)[id] || false;

export const useToggleTopicExpanded = (id: string) => {
  const set = useSetRecoilState(expandedTopicsState);

  return (value?: boolean) =>
    set((prev) => ({
      ...prev,
      [id]: value || !prev[id],
    }));
};

export const useFetchIdeateTopicQueries = () =>
  useMutation(
    ({
      project,
      sqid,
      topic,
      exclude,
      projectId,
    }: {
      project: string;
      topic: string;
      sqid: string;
      exclude: string;
      projectId: number;
    }) =>
      superagent
        .post(`${process.env.NEXT_PUBLIC_NLP_BASE}/ideatequeries`)
        .send({
          project,
          topic,
          sqid,
          projectId,
          exclude,
        })
        .withCredentials(),
  );
