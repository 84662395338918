import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';

import { useCallback } from 'react';
import { uniqBy } from 'lodash';
import { FilterGroup } from '../types/filterGroup';
import { useExploreQuery } from '../exploreQuery';
import { FilterOption } from '../types/filterOption';
import { getTopDomains } from '@/ext/app/utils/topDomains';

export const SITE_GROUP_NAME = 'site';

const defaultOptions = [
  { label: '.gov', value: '.gov', groupName: SITE_GROUP_NAME },
  { label: '.edu', value: '.edu', groupName: SITE_GROUP_NAME },
  {
    label: 'bloomberg.com',
    value: 'bloomberg.com',
    groupName: SITE_GROUP_NAME,
  },
  { label: 'youtube.com', value: 'youtube.com', groupName: SITE_GROUP_NAME },
  { label: 'reddit.com', value: 'reddit.com', groupName: SITE_GROUP_NAME },
  {
    label: 'crunchbase.com',
    value: 'crunchbase.com',
    groupName: SITE_GROUP_NAME,
  },
  { label: 'twitter.com', value: 'twitter.com', groupName: SITE_GROUP_NAME },
  { label: 'linkedin.com', value: 'linkedin.com', groupName: SITE_GROUP_NAME },
  { label: 'amazon.com', value: 'amazon.com', groupName: SITE_GROUP_NAME },
];

export const useSiteFilterGroupOptions = (
  query: string,
): UseInfiniteQueryResult<FilterOption[], Error> =>
  useInfiniteQuery(
    ['site-filter-group-options', query],
    async () => {
      const domains = await getTopDomains(query);
      return uniqBy(
        [
          ...defaultOptions,
          ...domains.map((domain) => ({
            ...domain,
            groupName: SITE_GROUP_NAME,
          })),
        ],
        'value',
      );
    },
    {
      getNextPageParam: undefined,
    },
  );

const filter: Omit<FilterGroup, 'query'> = {
  name: 'Site',
  id: SITE_GROUP_NAME,
  tooltip:
    'Filter by any site or top level domain, like ".gov". You can filter by groups of sites using our Lens feature.',
  multi: true,
};

export const useSiteFilterGroup = () => filter;

export const useSiteFilterGroupWithOptions = (): FilterGroup => {
  const filterGroup = useSiteFilterGroup();
  const query = useExploreQuery();
  const options = useSiteFilterGroupOptions(query);
  const getExpandLabel = useCallback(
    (num) => `See all ${num} site suggestions`,
    [],
  );

  return {
    ...filterGroup,
    getExpandLabel,
    query: options,
  };
};
