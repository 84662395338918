import { EXPLORE_INPUT_ID } from '../ExploreFormInput';
import { EXPLORE_FORM_LIST_ITEM_ID } from './constants';

export const filterListHandler = (
  e: React.KeyboardEvent,
  onApply: () => void,
  onComplete: () => void,
) => {
  e.preventDefault();
  const listItems = document.querySelectorAll(`#${EXPLORE_FORM_LIST_ITEM_ID}`);
  const index = Array.from(listItems).findIndex((item) => item === e.target);

  switch (e.key) {
    case 'ArrowDown':
      if (index < listItems.length - 1) {
        (listItems[index + 1] as HTMLElement).focus();
      } else {
        document.getElementById(EXPLORE_INPUT_ID)?.focus();
      }
      break;
    case 'ArrowUp':
      if (index > 0) {
        (listItems[index - 1] as HTMLElement).focus();
      } else {
        document.getElementById(EXPLORE_INPUT_ID)?.focus();
      }
      break;
    case 'Enter':
      onApply();
      break;
    case 'Tab':
      onComplete();
      document.getElementById(EXPLORE_INPUT_ID)?.focus();
      break;
    default:
      break;
  }
};
