import { useCallback } from 'react';
import {
  useQuery,
  useMutation,
  UseQueryResult,
  UseMutationResult,
  UseInfiniteQueryOptions,
  useInfiniteQuery,
  UseInfiniteQueryResult,
  InfiniteData,
  FetchQueryOptions,
} from 'react-query';

import { filter, flatten, isEmpty, map, reject } from 'lodash/fp';
import {
  atom,
  atomFamily,
  selectorFamily,
  SetterOrUpdater,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';

import { WaldoApiError, WaldoAPI } from '../../../lib/api';
import { TrackableEvent, TrackableTarget } from '../../../lib/trackable';
import { queryClient } from '@/components/providers/Dependencies';
import { getNextPageParam } from '../../utils/getNextPageParam';
import { useTracking } from '../tracking';
import { Lens, LensCreateInput, LensUpdateInput, LensResource } from './types';

export type { Lens, LensCreateInput } from './types';

export class LensAPI extends WaldoAPI {
  private baseUrl = '/v1/lens';

  public async own(
    query?: string,
    skip?: number,
    limit?: number,
  ): Promise<Lens[]> {
    return this.request({
      method: 'GET',
      path: this.baseUrl,
      query: {
        query,
        limit,
        skip,
      },
    });
  }

  public async getById(id: number): Promise<Lens> {
    return this.request({
      method: 'GET',
      path: `${this.baseUrl}/${id}`,
    });
  }

  public async getByTitle(title: string): Promise<Lens | null> {
    try {
      return await this.request({
        method: 'GET',
        path: `${this.baseUrl}/find_by_title`,
        query: {
          title,
        },
      });
    } catch (err) {
      if (err instanceof WaldoApiError && err.status === 404) {
        return null;
      }
      throw err;
    }
  }

  public async search(
    query: string,
    skip?: number,
    limit?: number,
  ): Promise<Lens[]> {
    return this.request({
      method: 'GET',
      path: `${this.baseUrl}/search`,
      query: {
        query,
        limit,
        skip,
      },
    });
  }

  public async related(
    urls: string[],
    skip?: number,
    limit?: number,
  ): Promise<Lens[]> {
    return this.request({
      method: 'POST',
      path: `${this.baseUrl}/related`,
      query: {
        limit,
        skip,
      },
      body: {
        urls,
      },
    });
  }

  public async suggested(): Promise<Lens[]> {
    return this.request({
      method: 'GET',
      path: '/v1/lens/suggested',
    });
  }

  public async create(lens: LensCreateInput): Promise<Lens> {
    return this.request({
      method: 'POST',
      path: this.baseUrl,
      body: lens,
    });
  }

  public async update(lens: LensUpdateInput): Promise<Lens> {
    const { lensId, ...body } = lens;
    return this.request({
      method: 'PUT',
      path: `${this.baseUrl}/${lensId}`,
      body,
    });
  }

  public async addResources(
    lensId: number,
    resources: LensResource[],
  ): Promise<Lens> {
    return this.request({
      method: 'POST',
      path: `${this.baseUrl}/${lensId}/resources`,
      body: resources,
    });
  }

  public async deleteResources(lensId: number, urls: string[]): Promise<Lens> {
    return this.request({
      method: 'DELETE',
      path: `${this.baseUrl}/${lensId}/resources`,
      body: urls,
    });
  }

  public async delete(lens: Lens): Promise<void> {
    return this.request({
      method: 'DELETE',
      path: `${this.baseUrl}/${lens.lensId}`,
    });
  }
}

const api = new LensAPI();

export const keys = <const>{
  all: <const>['lens'],
  list: <const>['lens', 'list'],
  id: (id: number) => <const>['lens', 'id', id],
  title: (title: string) => <const>['lens', 'title', title],
  own: (params: { query?: string; skip?: number; limit?: number }) =>
    <const>['lens', 'list', 'own', params],
  search: (params: { query: string; skip?: number; limit?: number }) =>
    <const>['lens', 'list', 'search', params],
  related: (params: { urls: string[]; skip?: number; limit?: number }) =>
    <const>['lens', 'list', 'related', params],
  suggested: <const>['lens', 'suggested'],
};

/* Cache propogation */

function useRemoveLensQueries(key = keys.list) {
  return useCallback(() => {
    queryClient.removeQueries(key, { active: false });
  }, [key]);
}

function updateCacheForInfiniteLenses(data: InfiniteData<Lens[]>) {
  (data.pages || []).forEach((lenses) => {
    lenses.forEach((lens) => {
      queryClient.setQueryData(keys.id(lens.lensId), lens);
      queryClient.setQueryData(keys.title(lens.title), lens);
    });
  });
}

/* Fetching lenses */

export const useLensById = (id?: number): UseQueryResult<Lens> =>
  useQuery(keys.id(id || -1), () => (id ? api.getById(id) : null), {
    enabled: !!id,
    onSuccess: (lens) =>
      lens && queryClient.setQueryData(keys.title(lens.title), lens),
  });

export function useOwnLenses(
  {
    query,
    skip,
    limit,
  }: {
    query?: string;
    skip?: number;
    limit?: number;
  } = {},
  options?: UseInfiniteQueryOptions<
    Lens[],
    Error,
    Lens[],
    Lens[],
    ReturnType<typeof keys['own']>
  >,
): UseInfiniteQueryResult<Lens[], Error> {
  return useInfiniteQuery(
    keys.own({ query, skip, limit }),
    ({ pageParam }) => api.own(query, pageParam || skip || undefined, limit),
    {
      onSuccess: updateCacheForInfiniteLenses,
      getNextPageParam,
      ...options,
    },
  );
}

export function useVisibleLenses(
  {
    query,
    skip,
    limit,
  }: {
    query: string;
    skip?: number;
    limit?: number;
  },
  options?: UseInfiniteQueryOptions<
    Lens[],
    Error,
    Lens[],
    Lens[],
    ReturnType<typeof keys['search']>
  >,
): UseInfiniteQueryResult<Lens[], Error> {
  return useInfiniteQuery(
    keys.search({ query, skip, limit }),
    ({ pageParam }) => api.search(query, pageParam || skip || undefined, limit),
    {
      onSuccess: updateCacheForInfiniteLenses,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.length === 0 ? undefined : flatten(allPages).length,
      ...options,
    },
  );
}

export function useRelatedLenses(
  {
    urls,
    skip,
    limit,
  }: {
    urls: string[];
    skip?: number;
    limit?: number;
  },
  options?: UseInfiniteQueryOptions<
    Lens[],
    Error,
    Lens[],
    Lens[],
    ReturnType<typeof keys['related']>
  >,
): UseInfiniteQueryResult<Lens[], Error> {
  return useInfiniteQuery(
    keys.related({ urls, skip, limit }),
    ({ pageParam }) => api.related(urls, pageParam || skip, limit),
    {
      onSuccess: updateCacheForInfiniteLenses,
      getNextPageParam: (lastPage, allPages) =>
        lastPage.length === 0 ? undefined : flatten(allPages).length,
      ...options,
    },
  );
}

export function useLensByTitle(title: string): UseQueryResult<Lens> {
  return useQuery(
    keys.title(title),
    () => (title.trim() ? api.getByTitle(title) : Promise.resolve(undefined)),
    {
      onSuccess: (lens) =>
        lens && queryClient.setQueryData(keys.id(lens.lensId), lens),
    },
  );
}

/* Creating lenses */

export function useCreateLens(): UseMutationResult<
  Lens,
  unknown,
  LensCreateInput
> {
  const trackEvent = useTracking();

  return useMutation((lens) => api.create(lens), {
    async onSuccess(data) {
      queryClient.removeQueries(keys.list);
      queryClient.removeQueries(keys.title(data.title));
    },
    onMutate() {
      trackEvent(TrackableEvent.ACTION, {
        target: TrackableTarget.CREATE_LENS,
      });
    },
    async onSettled(_data, _err) {
      // TODO set some global error message
    },
  });
}

/* Updating lenses */

export function useUpdateLens(): UseMutationResult<
  Lens,
  unknown,
  LensUpdateInput
> {
  const removeQueries = useRemoveLensQueries();

  return useMutation((lens) => api.update(lens), {
    onMutate(lens) {
      removeQueries();

      const queries = queryClient.getQueriesData<InfiniteData<Lens[]>>(
        keys.list,
      );
      queries.forEach(([key, data]) => {
        queryClient.setQueryData(key, {
          ...data,
          pages: data.pages.map((page) =>
            page.map((l) => (lens.lensId === l.lensId ? { ...l, ...lens } : l)),
          ),
        });
      });

      queryClient.setQueryData(keys.id(lens.lensId), lens);
      queryClient.setQueryData(keys.title(lens.title), lens);
    },
    async onSettled(_data, _err) {
      // TODO set some global error message
      await queryClient.invalidateQueries(keys.all);
    },
  });
}

/* Convenience hooks */

export const useFavouritesLens = (): UseQueryResult<Lens> =>
  useLensByTitle('Favorites');

export const useTrustedLens = (): UseQueryResult<Lens> =>
  useLensByTitle('Trusted');

export const useBlacklistLens = (): UseQueryResult<Lens> =>
  useLensByTitle('Blacklist');

export function useAddResourcesToLens(): UseMutationResult<
  Lens,
  Error,
  { lensId: number; resources: LensResource[] }
> {
  const removeQueries = useRemoveLensQueries();
  const trackEvent = useTracking();
  const { data: favourite } = useFavouritesLens();
  const { data: trusted } = useTrustedLens();
  const { data: blacklist } = useBlacklistLens();

  return useMutation(
    ({ lensId, resources }) => api.addResources(lensId, resources),
    {
      onMutate({ lensId, resources }) {
        removeQueries();

        if (
          lensId === favourite?.lensId ||
          lensId === trusted?.lensId ||
          lensId === blacklist?.lensId
        ) {
          trackEvent(TrackableEvent.ACTION, {
            target: TrackableTarget.ADD_SOURCE_TO_SPECIAL_LENS,
          });
        } else {
          trackEvent(TrackableEvent.ACTION, {
            target: TrackableTarget.ADD_SOURCE_TO_LENS,
          });
        }

        const currentLens = queryClient.getQueryData<Lens>(keys.id(lensId));
        if (currentLens) {
          const updatedLens = {
            ...currentLens,
            resources: [...currentLens.resources, ...resources],
          };

          const queries = queryClient.getQueriesData<InfiniteData<Lens[]>>(
            keys.list,
          );
          queries.forEach(([key, data]) => {
            queryClient.setQueryData(key, {
              ...data,
              pages: data.pages.map((page) =>
                page.map((lens) =>
                  lens.lensId === lensId ? updatedLens : lens,
                ),
              ),
            });
          });

          queryClient.setQueryData(keys.id(lensId), updatedLens);
          queryClient.setQueryData(keys.title(updatedLens.title), updatedLens);
        }
      },
      async onSettled(_data, _err) {
        // TODO set some global error message
        await queryClient.invalidateQueries(keys.all);
      },
    },
  );
}

export function useDeleteResourcesFromLens(): UseMutationResult<
  Lens,
  Error,
  { lensId: number; resources: string[] }
> {
  const removeQueries = useRemoveLensQueries();

  return useMutation(
    ({ lensId, resources }) => api.deleteResources(lensId, resources),
    {
      onMutate({ lensId, resources }) {
        removeQueries();

        const currentLens = queryClient.getQueryData<Lens>(keys.id(lensId));
        if (currentLens) {
          const updatedLens = {
            ...currentLens,
            resources: currentLens.resources.filter(
              ({ url }) =>
                !resources.some((resource) => url.includes(resource)),
            ),
          };

          const queries = queryClient.getQueriesData<InfiniteData<Lens[]>>(
            keys.list,
          );
          queries.forEach(([key, data]) => {
            queryClient.setQueryData(key, {
              ...data,
              pages: (data as InfiniteData<Lens[]>).pages.map((page) =>
                page.map((lens) =>
                  lens.lensId === lensId ? updatedLens : lens,
                ),
              ),
            });
          });

          queryClient.setQueryData(keys.id(lensId), updatedLens);
          queryClient.setQueryData(keys.title(updatedLens.title), updatedLens);
        }
      },
      async onSettled(_data, _err) {
        // TODO set some global error message
        await queryClient.invalidateQueries(keys.all);
      },
    },
  );
}

/* Deleting lenses */

export function useDeleteLens(): UseMutationResult<void, unknown, Lens> {
  const removeQueries = useRemoveLensQueries();

  return useMutation((lens) => api.delete(lens), {
    onMutate({ lensId, title }) {
      removeQueries();

      const queries = queryClient.getQueriesData<InfiniteData<Lens[]>>(
        keys.list,
      );
      queries.forEach(([key, data]) => {
        queryClient.setQueryData(key, {
          ...data,
          pages: (data as InfiniteData<Lens[]>).pages.map((page) =>
            page.filter((l) => l.lensId !== lensId),
          ),
        });
      });

      queryClient.removeQueries(keys.id(lensId));
      queryClient.removeQueries(keys.title(title));
    },
    async onSettled(_data, _err) {
      // TODO set some global error message
      await queryClient.invalidateQueries(keys.all);
    },
  });
}

/* Utility methods */

export const fetchLensByTitle = async (title: string): Promise<Lens> =>
  queryClient.fetchQuery({
    queryKey: keys.title(title),
    queryFn: () => api.getByTitle(title),
  });

export const fetchFavoriteLens = (): Promise<Lens> =>
  fetchLensByTitle('Favorites');

export const fetchTrustedLens = (): Promise<Lens> =>
  fetchLensByTitle('Trusted');

export const fetchLensesByTitles = async (
  titles: string[] = [],
): Promise<Lens[]> => {
  const lenses = await Promise.all(map(fetchLensByTitle, titles));
  return reject(isEmpty, lenses);
};

export const fetchLensById = async (
  id: number,
  options?: FetchQueryOptions<Lens>,
): Promise<Lens> =>
  queryClient.fetchQuery<Lens>({
    queryKey: keys.id(id),
    queryFn: () => api.getById(id),
    staleTime: Infinity,
    ...options,
  });

export const fetchLensesByIds = async (ids: string[] = []): Promise<Lens[]> =>
  (await Promise.all(ids.map((id) => fetchLensById(parseInt(id, 10))))).filter(
    Boolean,
  ) as Lens[];

export const isSpecial = (lens?: Lens): boolean => !!lens?.specialLensId;

export const selectedLensState = atom<Lens | undefined>({
  key: 'selectedLens',
  default: undefined,
});

export const useSelectedLens = (): Lens | undefined =>
  useRecoilValue(selectedLensState);
export const useSetSelectedLens = (): SetterOrUpdater<Lens | undefined> =>
  useSetRecoilState(selectedLensState);

export const getLensById = (id: number): Lens | undefined =>
  queryClient.getQueryData(keys.id(id));

export const getLensByTitle = (title: string): Lens | undefined =>
  queryClient.getQueryData(keys.title(title));

const lensMenuAnchorState = atomFamily<HTMLElement | null, string>({
  key: 'lensMenuAnchor',
  default: null,
});

export const useLensMenuAnchor = (url: string): HTMLElement | null =>
  useRecoilValue(lensMenuAnchorState(url));
export const useSetLensMenuAnchor = (
  url: string,
): SetterOrUpdater<HTMLElement | null> =>
  useSetRecoilState(lensMenuAnchorState(url));

const showLensMenuState = atomFamily<boolean, string>({
  key: 'showLensMenu',
  default: false,
});

export const useShowLensMenu = (url: string): boolean =>
  useRecoilValue(showLensMenuState(url));

export const useSetShowLensMenu = (url: string): SetterOrUpdater<boolean> =>
  useSetRecoilState(showLensMenuState(url));

/**
 * Globally suggested lenses
 */
export function useSuggestedLenses(): UseQueryResult<Lens[]> {
  return useQuery(keys.suggested, () => api.suggested());
}

export function useFilteredSuggestedLenses(query: string): Lens[] {
  const { data = [] } = useSuggestedLenses();
  return data.filter(({ title }) =>
    title.toLowerCase().includes(query.toLowerCase()),
  );
}

/**
 * Get Lens By Id
 *
 * TODO move to lens area
 */
export const lensState = selectorFamily<Lens, number>({
  key: 'lensState',
  get: (id) => async () => fetchLensById(id),
});
