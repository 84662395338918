import {
  Box,
  Tooltip as MuiTooltip,
  styled,
  ThemeProvider,
  useTheme,
  Typography,
} from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { VirtualElement } from '@popperjs/core';

import { Lens } from '@/ext/app/state/lenses';
import LensSummary from '../LensSummary';

export interface LensTooltipProps {
  lens: Lens;
  anchorEl?: VirtualElement;
}

const Tooltip = styled(MuiTooltip)(() => ({
  width: '100%',
}));

const LensTooltip: FunctionComponent<LensTooltipProps> = ({
  children,
  lens,
  anchorEl,
}) => {
  const theme = useTheme();
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <Tooltip
      placement="right"
      PopperProps={anchorEl ? { anchorEl } : undefined}
      title={
        <>
          <Typography paddingX={1.5} variant="h5">
            {lens.title}
          </Typography>
          <LensSummary lens={lens} maxWidth={288} padding={1.5} />
        </>
      }
      open={showTooltip}
    >
      <Box
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        onClick={() => setShowTooltip(false)}
      >
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </Box>
    </Tooltip>
  );
};

export default LensTooltip;
