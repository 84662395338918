import { useCallback } from 'react';
import { useInfiniteQuery, UseInfiniteQueryResult } from 'react-query';

import countryOptions from '../../../utils/countries.json';
import { FilterGroup } from '../types/filterGroup';
import { FilterOption } from '../types/filterOption';

const useCountryFilterGroupOptions = (): UseInfiniteQueryResult<
  FilterOption[],
  Error
> =>
  useInfiniteQuery(
    ['country-filter-group-options'],
    () =>
      countryOptions.map((data) => ({
        ...data,
        groupName: 'country',
      })),
    {
      getNextPageParam: undefined,
    },
  );

const filter: Omit<FilterGroup, 'query'> = {
  name: 'Country',
  id: 'country',
  tooltip:
    'Filter your results to domains and publications native to a specific country.',
};

export const useCountryFilterGroup = (): Omit<FilterGroup, 'query'> => filter;

export const useCountryFilterGroupWithOptions = (): FilterGroup => {
  const filterGroup = useCountryFilterGroup();
  const options = useCountryFilterGroupOptions();
  const getExpandLabel = useCallback((num) => `See all ${num} countries`, []);

  return {
    ...filterGroup,
    getExpandLabel,
    query: options,
  };
};
