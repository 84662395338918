import wordsToNumbers from 'words-to-numbers';

export const converWordToInteger = (n: string | number): number | undefined => {
  if (typeof n === 'number') {
    return parseInt(n.toString(), 10);
  }

  const num = parseInt(wordsToNumbers(n)?.toString() || '', 10);

  if (typeof num === 'number') {
    return num;
  }

  return undefined;
};

export const writtenNumbers = [
  'one',
  'two',
  'three',
  'four',
  'five',
  'six',
  'seven',
  'eight',
  'nine',
  'ten',
  'eleven',
  'twelve',
  'thirteen',
  'fourteen',
  'fifteen',
  'sixteen',
  'seventeen',
  'eighteen',
  'nineteen',
  'twenty',
  'thirty',
  'forty',
  'fifty',
  'sixty',
  'seventy',
  'eighty',
  'ninety',
  'hundred',
];
