import {
  Box,
  BoxProps,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';
import { FunctionComponent } from 'react';
import { take } from 'lodash/fp';

import { getCleanedURLPath } from '@/ext/app/utils/url';
import { Lens } from '@/ext/app/state/lenses';

export interface LensSummaryProps extends BoxProps {
  lens: Lens;
  limit?: number;
  variant?: TypographyProps['variant'];
}

const Label = styled(Typography)(() => ({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  maxWidth: '100%',
}));

const LensSummary: FunctionComponent<LensSummaryProps> = ({
  lens,
  limit = 10,
  variant = 'body2',
  ...props
}) => (
  <Box display="flex" flexDirection="column" gap={1.25} {...props}>
    {lens.description && (
      <Typography variant={variant}>{lens.description}</Typography>
    )}
    <Box display="flex" flexDirection="column" gap={0.75}>
      {take(
        limit,
        lens.resources.map(({ url, title }) => (
          <Label variant={variant} key={url}>
            {title
              ? `${getCleanedURLPath(url)} - ${title}`
              : getCleanedURLPath(url)}
          </Label>
        )),
      )}
      {lens.resources.length > limit && (
        <Typography variant={variant}>
          {`+${lens.resources.length - limit} more`}
        </Typography>
      )}
      {lens.resources.length === 0 && (
        <Typography variant={variant}>No sources.</Typography>
      )}
    </Box>
  </Box>
);

export default LensSummary;
