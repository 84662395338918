import { forwardRef } from 'react';
import { styled, SvgIconProps } from '@mui/material';

import Image from './image.svg';

const Icon = styled(Image)(() => ({
  '&.MuiSvgIcon-root': {
    width: 'auto',
    height: 'auto',
  },
}));

export const QuickSearch = forwardRef<SVGSVGElement, SvgIconProps>(
  (props, ref) => (
    <Icon
      innerRef={ref}
      component={Image}
      viewBox="0 0 15 15"
      fill="none"
      {...props}
    />
  ),
);
