import { captureException } from '@sentry/nextjs';
import {
  assign,
  each,
  entries,
  filter,
  flow,
  join,
  map,
  mapKeys,
  omit,
} from 'lodash/fp';

import * as selectors from './constants';
import { log } from '../../utils/log';
import { GoogleElementsType } from './useTrackGoogleElementsEvent';

export interface QueryStringValues {
  allowTypo?: string;
  country?: string;
  from?: string;
  language?: string;
  searchQuery: string;
  start?: string;
  to?: string;
  pageSize?: string;
}

export const fieldToParamMap = <const>{
  allowTypo: 'nfpr',
  country: 'cr',
  from: 'cd_min',
  language: 'lr',
  searchQuery: 'q',
  start: 'start',
  to: 'cd_max',
  pageSize: 'num',
};

const timeFieldName = 'tbs';
const timeFieldPrefix = 'cdr:1,';

export const getCorrectedSearchQuery = (
  contentDocument: Document = document,
): string | undefined =>
  contentDocument.querySelector<HTMLAnchorElement>(
    selectors.SEARCH_CORRECTED_SEARCH_QUERY_SELECTOR,
  )?.innerText;

export const getNextPageUrl = (
  contentDocument: Document = document,
): string | undefined =>
  contentDocument.querySelector<HTMLAnchorElement>(
    selectors.SEARCH_RESULTS_NEXT_LINK_SELECTOR,
  )?.href;

export const getSearchInput = (
  contentDocument: Document = document,
): HTMLInputElement | undefined =>
  contentDocument.querySelector<HTMLInputElement>(
    selectors.SEARCH_INPUT_SELECTOR,
  ) || undefined;

export const getUpdatedUrl = (
  currentUrl: string,
  qv: QueryStringValues,
): string => {
  let url: URL;

  try {
    url = new URL(currentUrl);
  } catch (error) {
    log('Unable to get updated url: ', error);
    captureException(error);
    return '';
  }

  const timeField: string = flow(
    () => <const>['from', 'to'],
    filter((x) => !!qv[x]),
    map((x) => `${fieldToParamMap[x]}:${qv[x]}`),
    join(','),
    (x) => x && `${timeFieldPrefix}${x}`,
  )();

  flow(
    () => qv,
    omit(['from', 'to', 'engine']),
    mapKeys((x: string) => fieldToParamMap[x as keyof QueryStringValues]),
    assign({ [timeFieldName]: timeField }),
    entries,
    each(([param, value]) => {
      if (value) {
        url.searchParams.set(param, value);
      } else {
        url.searchParams.delete(param);
      }
    }),
  )();

  return url.toString();
};

/**
 * Google Elements
 */

export interface GoogleElementItem {
  htmlElement: HTMLElement;
  link?: boolean | string;
  observeChanges?: boolean;
  originalElement: HTMLElement;
  type?: GoogleElementsType;
  width?: number;
}

export const isPeopleAlsoAsk = (element: HTMLElement): boolean => {
  const isTopStories =
    element
      .querySelector<HTMLElement>(selectors.TOP_STORIES_HEADING_SELECTOR)
      ?.innerText?.toLowerCase() === 'top stories';

  return !!(
    (element.matches(selectors.PEOPLE_ALSO_ASK_SELECTOR) ||
      element.querySelector(selectors.PEOPLE_ALSO_ASK_SELECTOR)) &&
    !isTopStories
  );
};

export const isGoogleElement = (element: HTMLElement): boolean => {
  const {
    ACTORS_AND_BUSINESS,
    APPBAR_CONTENT_SELECTOR,
    FEATURED_ELEMENT_SELECTOR,
    MEMBERS_SELECTOR,
    OVERVIEW_SELECTOR,
    SEARCH_INSTEAD_SELECTOR,
    STOCK_SELECTOR,
    PLACES_SELECTOR,
  } = selectors;
  // a google element is either inside the .ULSxyf class or has a different selector entirely

  return !!(
    (element.matches('#rso > div.ULSxyf') ||
      element.matches(ACTORS_AND_BUSINESS) ||
      element.matches(APPBAR_CONTENT_SELECTOR) ||
      element.matches(FEATURED_ELEMENT_SELECTOR) ||
      element.matches(MEMBERS_SELECTOR) ||
      element.matches(OVERVIEW_SELECTOR) ||
      element.matches(SEARCH_INSTEAD_SELECTOR) ||
      element.matches(STOCK_SELECTOR) ||
      element.matches(PLACES_SELECTOR)) &&
    !isPeopleAlsoAsk(element)
  );
};

export const isSearchResult = (element: HTMLElement): boolean => {
  const {
    CONVERSIONS_SELECTOR,
    CURRENCY_CONVERSION_SELECTOR,
    DIRECTIONS_SEARCH_SELECTOR,
    SEARCH_RESULT_FEATURED_SELECTOR,
    OVERVIEW_SELECTOR,
    STATISTICS_SELECTOR,
    TWITTER_SELECTOR,
    STOCK_SELECTOR,
  } = selectors;
  /**
   * a search result has a .g class, but sometimes google elements also have a g class,
   * which is the case for the elements below, so we have to disconsider them
   */
  const conversion = element.querySelector(CONVERSIONS_SELECTOR);
  const currency = element.querySelector(CURRENCY_CONVERSION_SELECTOR);
  const directionsSearch = element.querySelector(DIRECTIONS_SEARCH_SELECTOR);
  const snippet = element.querySelector(SEARCH_RESULT_FEATURED_SELECTOR);
  const overview = element.querySelector<HTMLElement>(OVERVIEW_SELECTOR);
  const stats = element.querySelector<HTMLElement>(STATISTICS_SELECTOR);
  const twitter = element.querySelector<HTMLElement>(TWITTER_SELECTOR);
  const stock = element.querySelector<HTMLElement>(STOCK_SELECTOR);

  return !!(
    element.querySelector('.g') &&
    !currency &&
    !conversion &&
    !directionsSearch &&
    !overview &&
    !snippet &&
    !stats &&
    !stock &&
    !twitter
  );
};

/**
 * - we don't want to show ADS
 * - VIDEOS LIST are already shown on the right side panel, this selector is for
 *   the list and not for the one video result
 * - KNOWLEDGE BOX are already shown on the right side panel
 */
export const removeElements = (elements: HTMLElement[]): HTMLElement[] => {
  const { ADS_ELEMENTS_SELECTOR, VIDEOS_SELECTOR, KNOWLEDGE_BOX_SELECTOR } =
    selectors;

  return elements.filter(
    (e) =>
      !e.matches(ADS_ELEMENTS_SELECTOR) &&
      !e.querySelector(VIDEOS_SELECTOR) &&
      !e.querySelector(KNOWLEDGE_BOX_SELECTOR),
  );
};
